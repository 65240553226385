.settings_header {
  position: absolute;
  top: 40px;
  left: 5%;
  right: 5%;
  height: 50px;
  border-radius: 10px 10px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .settings_title {
    font-size: 1.2rem;
    color: var(--text-main);
  }
  .settings_desc {
    font-size: 0.8rem;
    color: var(--text-secondary);
  }
}

.settings_content_management {
  position: absolute;
  top: 100px;
  left: 5%;
  right: 5%;
  bottom: 40px;
  border-radius: 10px;
  display: flex;
  background-color: var(--barsColor);
  .settings_tabs {
    width: 25%;
    max-width: 180px;
    border-radius: 10px 0 0 10px;
    color: var(--text-main);
    overflow: hidden;
    border-right: 1px solid var(--text-secondary);
    .settings_tab {
      height: 50px;
      display: flex;
      align-items: center;
      padding: 0 20px;
      border-bottom: 1px solid var(--text-secondary);
      font-size: 0.8rem;
      cursor: pointer;
      &:hover,
      &.active {
        background-color: var(--main-color);
      }
    }
  }
  .settings_content {
    width: 100%;
    padding: 20px;
    color: #fff;
    // border: 1px solid var(--text-main);
    border-radius: 0 10px 10px 0;
  }
}