.countdown {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
    margin-bottom: 100px;
  }
  
  .time-box {
    text-align: center;
    margin: 0 15px;
  }
  
  .time {
    font-size: 2rem;
    font-weight: bold;
    color: var(--text-main);
  }
  
  .time-label {
    font-size: 1rem;
    color: var(--text-secondary);
  }

  .image-div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
    margin-bottom: 50px;
  }

  .image {
    display: block;
    max-width: 20%;
    margin-top: 30px;
    border-radius: 200px;
    -webkit-border-radius: 200px;
    -moz-border-radius: 200px;
    -ms-border-radius: 200px;
    -o-border-radius: 200px;
}