.headerContainer {
  height: 120px;
  width: 100%;
  background: var(--barsColor);
  margin-top: 1.5rem;
  border-radius: 1rem;
  position: relative;
  z-index: 1;

  .headerTitle {
    color: var(--text-secondary);
    font-size: 22px;
  }

  .headerContent {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    align-items: center;
    overflow: hidden;

    .trashIcon {
      padding-left: 1.5rem;
      padding-right: 1rem;

      svg {
        path {
          fill: var(--text-secondary);
        }
      }
    }

    p {
      font-size: 16px;
      padding-top: 3px;
      color: var(--text-secondary);
    }
  }
  
}