.wrapper {
  width: 100%;
  .header {
   padding-top: 10px;
    color: white;
    display: flex;
    align-items: center;
    gap: 5px;
    h3{
      font-weight: 500;
      font-size: 18px;
      color: rgb(188, 188, 188);
    }
    svg{
      margin-top: 5px;
      path{
        fill: rgba(128, 128, 128, 0.363);
      }
      &:hover{
        cursor: pointer;
          path{
          fill: white;
        }
      }
    }
  }
  .content {
    transition: all 0.3s ease-in-out;
    transform: scaleY(0);
    transform-origin: top;
    height: 0;
    &.expanded {
      transform: scaleY(1);
      height: unset;
    }
  }
}
