.imgArea {
  height: 68%;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  margin-bottom: 30px;
  z-index: 1;

  img{
    object-fit: contain;
    max-height: 425px;
    height: 100%;
    border-radius: 10px;
    width: 100%;
    display: flex;
    margin: 0 auto;
  }

  .bottomLeftText {
    position: absolute;
    left: 20px;
    bottom: 30px;
    width: 240px;
    color: var(--text-secondary);
    h3{
      font-size: 25px;
      margin-bottom: 7px;
    }
  }
}