h2 {
  font-weight: normal;
}
.descriptionAndBtnContainer {
  width: 100%;
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  .descriptionContainer {
    width: 100%;
    p {
      color: var(--text-secondary);
      font-size: 0.8rem;
    }
  }
  .addImgsBtn {
    padding: 5px 20px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 15px;
    border: none;
    background-color: var(--primary-btn-color);
    color: var(--text-main);
    span {
      margin: 0 5px;
    }
    .addImgsBtnIcon {
      height: 12px;
    }
  }
}

.ImgsContainer {
  width: 100%;
  height: 140px;
  padding: 10px 0;
  display: flex;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  &::-webkit-scrollbar {
    background-color: var(--main-color);
  }
  &::-webkit-scrollbar-track {
    margin: 0;
  }
  &::-webkit-scrollbar-thumb {
    cursor: pointer;
  }
  .singleImgContainer {
    user-select: none;
    position: relative;
    height: 100%;
    margin-right: 15px;
    border-radius: 10px;
    box-sizing: content-box;
    img {
      border-radius: 10px;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      &:not(.inEditMode):hover {
        border: 1px solid var(--main-color);
      }
      &:not(.inEditMode).active {
        border: 1px solid var(--main-color);
      }
    }
    .imgChckbox {
      position: absolute;
      bottom: 5px;
      right: 5px;
      label{
      &:hover{
        cursor: pointer;
      }
    }
    }
    .deleteimgBtn {
      position: absolute;
      top: 5px;
      right: 5px;
      opacity: 0.4;
      transition: all 0.3s ease-in-out;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--primary-btn-color);
        border: none;
        height: 20px;
        width: 20px;
        font-size: 20px;
        padding-bottom: 3px;
        border-radius: 50%;
        color: var(--text-main);
      }
      &:hover {
        opacity: 1;
      }
    }
  }
  .noImgs {
    width: 100%;
    text-align: center;
  }
}

.selectAll {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0;
  * span,
  p {
    user-select: none;
    font-size: 0.95rem;
    color: var(--text-secondary);
  }
}
