.bottomAreaGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  color: var(--text-secondary);
  height: 90px;
  .bottomAreaGridChild {
    height: 100%;
    width: 100%;
    border-right: 2px solid #3e3e3e7d;
    padding-right: 10px;
    font-size: 10px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    .orangeText {
      color: var(--main-color);
    }
  }

  & div.bottomAreaGridChild:last-child {
    border-right: none;
  }
}
