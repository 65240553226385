.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-image: url(../../../assets//images/gears.svg);
  background-position: -5% 110%;
  background-size: clamp(150px, 25%, 350px);
  background-repeat: no-repeat;
  gap: 20px;
  padding: 0 15%;
  .img {
    width: 30%;
    max-width: 350px;
    min-width: 250px;
  }

  .header_container {
    max-width: 600px;
    .title {
      font: normal normal bold 35px/50px Raleway;
      color: var(--text-secondary);
      
    }
    .paragraph {
      font: normal normal normal 25px/30px Raleway;
      margin-top: 10px;
      color: var(--text-third);
    }
  }

}

@media (max-width: 1100px) {
  .container {
    background-image: url(../../../assets/images/gears.svg);
    background-position: 95% 95%;
    background-size: clamp(300px, 40%, 450px);
    background-repeat: no-repeat;
    flex-direction: column;
    padding: 0 10%;
    text-align: center;
    gap: 45px !important;
  }
}