.closeBtn {
  position: absolute;
  right: 15px;
  top: 20px;
  background-color: transparent;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  svg {
    width: 60%;
    height: 60%;
    * {
      stroke: var(--text-main) !important;
      fill: var(--text-main) !important;
    }
  }
}
.collectionContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  color: var(--text-main);
  .leftContainerOfImage {
    width: 55%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-right: 10px;
    .headerTitle {
      font-size: 1.2rem;
      margin: 10px 0 40px 0;
    }
    .collectionImageContainer {
      height: 250px;
      max-width: 100%;
      img {
        height: 100%;
        max-width: 100%;
        border-radius: 10px;
      }
    }
  }
  .rightContainerOfData {
    width: 45%;
    height: 100%;
    padding-top: 35px;
    display: flex;
    flex-direction: column;
    .input {
      width: 100%;
      height: 100%;
      outline: none;
      border: none;
      border-radius: 15px;
      background-color: var(--lighterbgColor);
      color: var(--text-main);
      font-size: 1rem;
      padding: 5px 30px 5px 25px;
    }
    .createCollectionContainer {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 35px;
      span {
        font-size: 1.2rem;
        padding-bottom: 5px;
        color: var(--text-secondary);
      }
      .createCollectionInputContainer {
        position: relative;
        height: 60px;
        width: 100%;
        padding: 10px 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        input {
          border-radius: 15px;
          border: none;
        }
        button {
          position: absolute;
          top: 10px;
          bottom: 10px;
          right: 6px;
          width: 40px;
          border-radius: 0 15px 15px 0;
          border: none;
          background-color: transparent;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            height: 40%;
          }
          svg {
            width: 23px;
            zoom: 0.7;
            polygon {
              fill: var(--text-secondary);
            }
            &:hover {
              g {
                polygon {
                  fill: var(--text-main);
                }
              }
            }
          }
        }
      }
    }
    .isPublicSelector {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      margin-top: 15px;
      width: 100%;
      .headerSpan {
        font-size: 1.2rem;
        margin: 10px 0;
        color: var(--text-secondary);
      }
      .isPublicSelector_radioContainer {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 5px 0;
        user-select: none;
        cursor: pointer !important;
        label {
          cursor: pointer !important;
        }
        .customRadio {
          height: 15px;
          width: 15px;
          border-radius: 50%;
          border: 1px solid var(--text-main);
          margin-right: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          &.active {
            border-color: var(--main-color);
          }
          .customRadio_inner {
            height: 100%;
            width: 100%;
            border-radius: 50%;
            font-size: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: var(--main-color);
          }
        }
      }
    }
    .allCollectionShowcaseContainer {
      height: 60%;
      min-height: 250px !important;
      width: 100%;
      margin-top: 30px;
      border: 1px solid var(--mainBgColor-opacity-shadow);
      background-color: var(--button-normal);
      border-radius: 15px;
      position: relative;
      overflow: hidden;
      .searchInputContainer {
        position: absolute;
        top: -1px;
        left: 0px;
        right: 0px;
        height: 50px;
        background-color: var(--button-normal);
        border-radius: 15px;
        overflow: hidden;
        input {
          padding: 5px 80px 5px 25px;
          background-image: url("../../../../../../assets/icons/searchmag.svg");
          background-repeat: no-repeat;
          background-position: 95% 50%;
          background-size: 20px;
          background-color: var(--lighterbgColor);
        }
      }
      .showcaseContainer {
        position: absolute;
        top: 60px;
        bottom: 0;
        height: 100%;
        width: 100%;
        overflow: hidden;
        .allCollectionShowcaseContainer_list {
          height: calc(100% - 70px);
          width: 100%;
          border-radius: 0 0 30px 30px;
          padding: 0 10px;
          display: flex;
          flex-direction: column;
          overflow-y: auto;
          overflow-x: hidden;
          .allCollectionShowcaseContainer_list_item {
            padding: 20px 10px;
            margin: 1px 0;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .collection_dataInfo {
              display: flex;
              align-items: center;
              img {
                height: 40px;
                width: 40px;
                margin: 0 10px 0 0;
              }
            }
            .btnInteractionContainer {
              .deselectBtn {
                height: 22px;
                width: 22px;
                border-radius: 50%;
                border: none;
                background-color: var(--button-normal);
                color: var(--text-main);
                font-size: 0.7rem;
                cursor: pointer;
                &:hover {
                  background-color: var(--main-color);
                }
              }
            }
            &:hover {
              background-color: var(--mainBgColor-opacity-shadow);
            }
            &.active {
              background-color: var(--barsColor);
            }
          }
          .noCollectionContainer {
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
    .saveButtonContainer {
      width: 100%;
      height: 20%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      button {
        padding: 10px 25px;
        border-radius: 30px;
        border: none;
        background-color: var(--main-color);
        color: white;
        font-size: 0.8rem;
        font-weight: 600;
        cursor: pointer;
        margin-top: 40px;
        &:hover {
          background-color: var(--main-color-brighter);
        }
      }
    }
  }
}

// media querry less than 800px
@media (max-width: 800px) {
  .collectionContainer {
    flex-direction: column;
    align-items: center;
    overflow-y: scroll !important;
    &::-webkit-scrollbar {
      display: none;
    }
    .leftContainerOfImage {
      width: 100% !important;
      height: unset !important;
    }
    .rightContainerOfData {
      width: 100% !important;
    }
    .collectionImageContainer {
      height: 160px !important;
      align-self: center !important;
    }
    .isPublicSelector {
      display: flex;
      flex-direction: row !important;
      justify-content: space-evenly;
      .isPublicSelector_radioContainer {
        justify-content: center !important;
      }
    }
  }
}
