@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');

.wrappingElement {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.searchBarContainer {
  position: sticky;
  top: -180px;
  height: 270px;
  width: 100%;
  padding: 25px 130px;
  z-index: 3;
  background: url("../../../assets/images/blackkk2.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  .searchBar {
    position: sticky;
    top: 35px;
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--mainBgColor-opacity-shadow);
    z-index: -1;
  }

  h1 {
    color: rgb(203, 203, 203);
    text-align: center;
    margin-top: 66px;
    font-family: 'Montserrat', sans-serif;
  }
}

.linksContainer {
  position: sticky;
  top: 75px;
  background-color: var(--mainBgColor);
  height: 80px;
  width: 100%;
  padding: 20px 0;
  z-index: 2;
  background-size: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  .linkItem {
    margin-top: 15px;
    .link {
      margin: 0 20px;
      color: var(--text-main);
      text-decoration: none;
      font-size: 20px;
    }
  }
}

.contentArea {
  width: 100%;
  padding-bottom: 1.5rem;
  background-color: var(--mainBgColor);
  padding: 30px 130px;
  z-index: 1;
  color: var(--text-main);
  .contentPerDataTypeContainer {
    width: 100%;
    margin-bottom: 30px;
    background-color: var(--container-blocks);
    border-radius: 30px;
    padding: 20px 50px;
    display: flex;
    flex-direction: column;
    .headerContainer {
      padding: 10px 0;
      width: 100%;
      z-index: 1;
      .header {
        font-size: 20px;
        font-weight: bold;
      }
    }
    .catalogsViewContainer {
      width: 100%;
      height: 100%;
      z-index: 10;
        .cardList {
        display: flex;
        overflow-x: auto;
        overflow-y: hidden;
        width: 100%;
        height: 100%;
        padding: 20px 0;
      }
    }
    .seeMoreContainer {
      width: 100%;
      padding: 10px 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .seeMoreLink {
        color: var(--text-main);
        text-decoration: none;
        font-weight: bold;
        font-size: 0.8rem;
        padding: 8px 25px;
        border-radius: 20px;
        background-color: var(--button-normal);
        &:hover {
          opacity: 0.8;
          background-color: var(--main-color-brighter);
          font-weight: 500;
          transform: scale(1.03);
        }
      }
    }
  }
}

.noCatalogs {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 355px);
  width: 100%;
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--text-main);
  text-align: center;
}

@media (max-width: 800px) {
  .searchBarContainer,
  .contentArea {
    padding: 30px 5%;
  }
}

@media (max-width: 500px) {
  .searchBarContainer,
  .contentArea {
    padding: 30px 20px;
  }
}