.wrappingElement {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.searchBarContainer {
  position: sticky;
  top: -95px;
  height: 170px;
  width: 100%;
  padding: 0 130px;
  z-index: 3;
  background: url("../../../assets/images/blackkk2.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  .searchBar {
    position: sticky;
    top: 35px;
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00000042;
    z-index: -1;
  }

  h1 {
    position: sticky;
    color: #cbcbcb;
    // text-align: center;
    top: 60px;
    font-family: 'Montserrat', sans-serif;
  }
}

.contentSizing {
  width: 100%;
  padding: 10px 140px 0 140px;
  z-index: 2;
  background-color: var(--mainBgColor);
  color: var(--text-main);
  color: white;
  position: sticky;
  top: 50px;

  .contentAreaHeaderMiniFilter {
    width: 100%;
    padding: 10px 0 0 0;

    .headerElement {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .headerContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        margin-top: 17px;

        h3.headerElementTitle {
          font-size: 18px;
          opacity: 1;
          font-weight: 500;
          color: var(--text-secondary);
        }

        span.headerElementTitle {
          font-size: 14px;
          opacity: .7;
          font-weight: lighter;
        }
      }
    }
  }

  .contentFilterFull {
    width: 100%;
    height: 100%;
  }

  .miniFiltringAndView {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    .miniFilterContainer {
      display: flex;
      align-items: center;
      width: calc(100% - 100px);
      overflow: hidden;
      .l_arrowBtn,
      .r_arrowBtn {
        height: 25px;
        width: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          height: 100%;
          width: 100%;
          opacity: .4;
          &:hover {
            cursor: pointer;
            opacity: .7;
            circle {
              fill: var(--primary-btn-color) !important;
            }
            path{
              fill: var(--text-secondary);
            }
          }
          circle {
            fill: transparent !important
          }
        }
      }
      .l_arrowBtn {
        margin-right: 2px;
      }
      .r_arrowBtn {
        margin-left: 2px;
      }
      .tagsContainer {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        overflow: scroll;
        gap: 10px;
        list-style-type: none;
        scroll-behavior: smooth;
        &::-webkit-scrollbar {
          height: 0 !important;
        }
        .tagSpan {
          white-space: nowrap;
          height: 28px;
          padding: 0 8px;
          border-radius: 30px;
          display: flex;
          align-items: center;
          font-size: 12px;
          background-color: var(--main-color);
          color: white;
          opacity: .7;
          &:last-of-type {
            margin-right: 0;
          }
          &:hover {
            cursor: pointer;
            font-weight: bold;
            opacity: 1;
          }
          &.active {
            background-color: var(--main-color-brighter);
            color: white;
            opacity: 1;
          }
        }
      }
    }

    .viewsContainer {
      display: flex;
      gap: 7px;
      margin-top: 12px;
      svg {
        zoom: .8;
        margin-top: -10px;

        rect {
          opacity: .7;
        }

        &:hover {
          cursor: pointer;

          rect {
            opacity: 1;
          }
        }
      }
    }
  }

  .sizingContianer {
    height: 100%;
    display: flex;
    align-items: center;
    gap: 20px;

    .filterByRoleContainer {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 7px;

      .filterByRoleSwingContainer {
        height: 35px;
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--text-main);
        overflow: hidden;
        gap: 15px;
        margin: 10px 0;

        .roleSpan {
          padding: 15px 0;
          border-radius: 30px;
          height: 100%;
          display: flex;
          align-items: center;
          color: var(--text-secondary);
          opacity: .8;
          font-size: 15px;
          
          &:hover, &.active_role {
            cursor: pointer;
            color: var(--text-main);
            opacity: 1;
            font-weight: bold;
          }
        }
      }
    }

  }
  .sizingBtn {
    height: 90%;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    opacity: 0.7;

    &:hover,
    &.active {
      opacity: 1;
      svg {
        filter: brightness(150%);
      }
    }
  }
}

.contentArea {
  background-color: var(--mainBgColor);
  width: 100%;
  padding-bottom: 1.5rem;
  padding: 10px 130px;
  z-index: 0;
  .noResultsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    color: white;
  }
}

span.headerElementTitle {
  color: var(--text-secondary);
}

h3.headerElementTitle {
  color: var(--text-third);
}

@media (max-width: 800px) {
  .searchBarContainer,
  .contentSizingMain,
  .contentSizing,
  .contentSizingByFolder,
  .contentArea {
    padding: 30px 5%;
    padding-top: 0 !important;
  }
  .linksContainer {
    height: unset !important;
    overflow-y: hidden;
    overflow-x: auto;
    &::-webkit-scrollbar {
      height: 0 !important;
      width: 0 !important;
    }
  }
}

@media (max-width: 500px) {
  .searchBarContainer,
  .contentSizingMain,
  .contentArea {
    padding: 30px 20px;
  }
}

@media (max-width: 600px) {
  .viewsContainer {
    display: none !important;
  }
  .miniFilterContainer {
    width: 100% !important;
  }
  .filterByRoleContainer {
    display: none !important;
  }
}