.popUpContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 40px;
}

.lightEditingContainer {
  width: 100%;
  height: 100%;
  .toggleBtnContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    .editorBtn {
      padding: 5px;
      height: 40px;
      width: 40px;
      border-radius: 5px;
      svg {
        height: 100%;
        width: 100%;
      }
      &:hover,
      &.active {
        background-color: var(--primary-btn-color);
      }
    }
  }
  .editorContainer {
    position: absolute;
    top: 10%;
    bottom: 10%;
    left: calc(100% + 20px);
    border-radius: 10px;
    background-color: var(--button-normal);
    transform: scaleX(0);
    transform-origin: left;
    transition: all 0.3s ease-in-out;
    padding: 20px 30px;
    &.active {
      transform: scaleX(1);
    }
    &:hover {
      width: 60%;
    }
  }
}


// media querry less than 800px

@media (max-width: 800px) {
  .popUpContainer {
    padding: 40px 20px;
  }
}