.mainContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .filterContainer {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        overflow-y: hidden;
        overflow-x: auto;
        gap: 10px;
        list-style-type: none;
        scroll-behavior: smooth;
        padding: 10px 0;
        .clearAll,
        .clearFilter {
          white-space: nowrap;
          height: 28px;
          padding: 0 8px;
          border-radius: 30px;
          display: flex;
          align-items: center;
          font-size: 12px;
          text-transform: capitalize;
          background-color: var(--container-blocks);
          color: var(--text-main);
          cursor: pointer;
          span {
            font-size: 11px;
            padding: 0px 7px;
            color: var(--text-main);
          }
          &:hover {
            filter: invert(1)
          }
        }
        .clearAll{
          white-space: nowrap;
          padding: 0 8px;
          display: flex;
          align-items: center;
          font-size: 12px;
          background-color: var(--text-main);
          color: var(--container-blocks);
          span {
            font-size: 11px;
            padding: 0px 7px;
            opacity: .8;
            color: var(--container-blocks);
          }
        }
      }
}