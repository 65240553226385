@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap");

.wrappingElement {
  position: absolute;
  top: -100px;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: url("../../../assets/images/upgrade_bg.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  .bgFilter {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.70);
    overflow-x: hidden;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-self: center;
    backdrop-filter: blur(10px);
  }
  .main_container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 10%;
    padding: 0 5%;
    .introapology {
      .header {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 800;
        font-size: 3rem;
        margin: 10px 0;
        background: linear-gradient(89.73deg, #F49959 6.35%, #6A41FB 89.63%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
      .sc_header {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 600;
        font-size: 2rem;
        color: #ffffff;
        margin: 10px 0;
      }
    }
    .entrance {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .info {
        font-family: "Raleway";
        font-style: normal;
        font-weight: bold;
        font-size: 2rem;
        text-align: center;
        color: #e7e3e3;
        line-height: 58px;
        margin-bottom: 10%;
        .colored_span {
          color: #F49959;
        }
      }
      button {
        background: linear-gradient(89.73deg, #F49959 6.35%, #6A41FB 89.63%);
        box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.5);
        border-radius: 46px;
        font-family: "Raleway";
        font-weight: 700;
        font-size: 1.1rem;
        line-height: 14px;
        color: #e7e7e7;
        padding: 20px 30px;
      }
    }
  }
  .mw_footer {
    height: 130px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .tags_container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 5%;
      height: 90px;
      padding: 0 5px;
      .tag_span {
        color: #e7e7e7;
        font-size: 1.1rem;
        opacity: 0.7;
        transition: all 0.3s ease-in-out;
        &:hover {
          opacity: 1;
        }
      }
    }
    .powerdBy_container{
      height: 40px;
      width: 100%;
      background-color: var(--barsColor);
      display: flex;
      justify-content: center;
      align-items: center;
      color: #909090;
    }
  }
}
