  .content {
    position: fixed;
    top: 55px;
    right: 0;
    left: 250px;
    bottom: 0;
    background-color: var(--mainBgColor);
    z-index: 20;
    transition: all 0.20s ease-in-out;
    &.closedMenu {
      left: 0 !important;
    }
    &.show {
      left: 240px !important;
    }
    &.hide {
      left: 50px !important;
    }
  }
  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 4px;
    margin: 2px 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--button-normal);
    border-radius: 4px;
    height: 30px;
  }

  @media (max-width: 1270px) {
    .content {
      left: 0 !important;
    }
  }