.tagsContainer {
    display: flex;
    align-items: center;
    height: 100%;
    .tag {
        border: none;
        font-size: 0.9rem;
        background-color: #434343;
        border-radius: 20px;
        color: white;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 16px 18px;
        margin-right: 15px;
        cursor: pointer;

        &:hover, &.active {
            background-color: var(--main-color);
            font-weight: bold;
        }
    }
}
