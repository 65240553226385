.miniPreviewContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.popupPreviewImgContainer__navigatorRight,
.popupPreviewImgContainer__navigatorLeft {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  width: 80px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 0;
  button {
    background-color: transparent;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: none;
    outline: none;
    cursor: pointer;
    color: white;
    font-size: 1.2rem;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.6;
    &:disabled {
      background-color: transparent !important;
      cursor: default !important;
      opacity: 0.1;
    }
    &:hover:not(:disabled) {
      opacity: 1;
    }
    svg {
      circle {
        fill: rgba(74, 74, 74, 0.848);
      }
      path {
        fill: white;
      }
    }
  }
}

.popupPreviewImgContainer__navigatorRight {
  left: unset !important;
  right: 20px;
}

.popupPreviewImgContainer {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(175, 175, 175);
  font-weight: 500;
  border-radius: 10px;
  outline: none;
  h3 {
    color: white;
    font-size: 24px;
  }
  &.loadingImgActiveBg {
    background-color: var(--barsColor) !important;
  }
  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 5px solid #fff;
    border-top: 5px solid var(--main-color);
    animation: spin 1s linear infinite;
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
  .previewImg {
    border-radius: 10px;
    &.loadingImg {
      visibility: hidden;
    }
  }
  .popupPreviewImgContainer__info {
    position: absolute;
    top: -60px;
    left: 0;
    right: 0;
    min-height: 50px;
    border-radius: 10px 10px 0 0;
    padding: 5px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .tagsContainer {
      display: flex;
      gap: 10px;

      .tag {
        padding: 5px 10px;
        background-color: #1f1f1faa;
        border-radius: 30px;
        color: #6d6d6d;
        font-size: 12px;
      }
    }
  }

  .popupPreviewImgContainer__footer {
    position: absolute;
    top: calc(100% + 10px);
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    border-radius: 0 0 9px 9px;
    padding: 5px 10px;
    p {
      font-size: 0.9rem;
      color: #fff;
    }
  }
  .popupPreviewImgContainer__controls {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    height: 40px;
    z-index: 10;
    display: flex;
    align-items: center;
    .fullscreenBtn {
      background-color: transparent;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: none;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        width: 60%;
        height: 60%;
      }
    }
  }
  .tagsContainer {
    position: absolute;
    overflow: hidden;
    top: calc(100% - 62px);
    left: 0;
    bottom: 0;
    right: 0;
    display: none;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    padding: 5px;
    gap: 5px;
    color: white;
    transition: all 0.3s ease-in-out;
    border-radius: 0 0 10px 10px;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.4) 100%
    );
    .tagSpan {
      font-size: 0.85rem;
      font-weight: bold;
    }
    .tag {
      border-radius: 30px;
      font-size: 12px;
    }
    &:hover {
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.8) 100%
      );
    }
  }
  &:hover {
    .tagsContainer {
      display: flex !important;
    }
  }
  &.fscreen {
    .backgroundOverlay {
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -5;
      width: 100%;
      background-color: #0000006e;
      filter: blur(15px);
    }
    .loadingImgActiveBgFs {
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
      width: 100%;
      background-image: url("https://media.tenor.com/On7kvXhzml4AAAAj/loading-gif.gif");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 50px;
    }
    .previewImg {
      border-radius: 0 !important;
    }
    .popupPreviewImgContainer__info {
      top: 0 !important;
      border-radius: 0 !important;
      justify-content: unset;
    }
    .popupPreviewImgContainer__footer {
      display: none;
    }
    .popupPreviewImgContainer__navigatorLeft {
      left: 0 !important;
    }
    .popupPreviewImgContainer__navigatorRight {
      right: 0 !important;
      svg {
        circle {
          fill: #292828 !important;
        }
      }
    }
    .openShowCase,
    .closeShowCase {
      height: 30px;
      padding: 5px;
      background-color: transparent;
      border: none;
      outline: none;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      .closeShowCaseImg {
        height: 100%;
      }
    }
    .openShowCase {
      transform: rotate(180deg);
      margin-right: 20px;
    }
    .fullscreenShowcaseContainer {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: black;
      z-index: 20;
      .fullscreenShowcaseContainer__interactiveTab {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
      }
      .catalogsWrapper {
        position: absolute;
        top: 50px;
        left: 0;
        width: 100%;
        height: calc(100% - 50px);
        overflow-x: hidden;
        overflow-y: auto;
      }
    }
  }
}
