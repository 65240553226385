.react-photo-gallery--gallery {
  padding: 20px 0;
  &.overflow {
    overflow-x: auto;
    overflow-y: hidden;
  }
  div img {
    border-radius: 10px;
    object-fit: cover;
  }
}