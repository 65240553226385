.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--barsColor);
  display: flex;
  justify-content: center;
  align-items: center;
  .logoContainer {
    svg {
      width: 100px;
      height: 100px;
      animation: spin 2s linear infinite;
    }
  }
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
}
