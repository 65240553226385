.middleSection {
  background: var(--maincolorgradient);
  border-radius: 50px;
  display: flex;
  align-items: center;
  min-height: 450px;
  padding: 50px 20px;
  color: #ffffff;
  .content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      gap: 120px;
      max-width: 85%;
      margin: 0 auto;
      .desc {
          display: flex;
          flex-direction: column;
          gap: 20px;
          .title {
              max-width: 400px;
              font-size: 36px;
              line-height: 38px;
          }
          .description {
              font-size: 18px;
              line-height: 26px;
          }
      }
      .images {
          display: flex;
          position: relative;
          img {
              width: 100%;
          }
      }
  }
}

@media (max-width: 1399px) {
  .middleSection {
      min-height: 350px;
      .content {
          max-width: 93%;
          gap: 30px;
          .desc {
              .title {
                  font-size: 25px;
                  line-height: 30px;
              }
              .description {
                  font-size: 16px;
              }
          }
      }
  }
}

@media (max-width: 1199px) {
  .middleSection {
      min-height: 350px;
      .content {
          max-width: 93%;
          grid-template-columns: 1fr;
          gap: 30px;
          .desc {
              .title {
                  font-size: 25px;
                  line-height: 30px;
              }
              .description {
                  font-size: 16px;
              }
          }
      }
  }
}