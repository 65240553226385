.container {
  display: flex;
  justify-content: center;
}

.container  {
  box-sizing: border-box;
  margin: 0;
  padding: 40px;
  border-radius: 20px;
  background: var(--maincolorgradient);
}

.recommendations__container {
  width: 100%;
  max-width: 1156px;
  //margin-bottom: 219px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.recommendations__container > h1 {
  font-family: Raleway, sans-serif;
  font-size: 30px;
  font-weight: 600;
  line-height: 35px;
  letter-spacing: 0;
  color: #FFFFFF;
  align-self: flex-start;
}

.recommendations__container > div {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  max-width: 1156px;
  column-gap: 21px;
  row-gap: 25px;
  margin-top: 51px;
}

.recommendations__container > div img {
  width: 100%;
  max-width: 368px;
  object-fit: cover;
}


@media (max-width: 700px) {
  .recommendations__container > div  {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}

@media (max-width: 400px) {
  .recommendations__container > div  {
    grid-template-columns: repeat(1, 1fr) !important;
    max-height: 500px;
    overflow-x: hidden;
    overflow-y: scroll;
  }
}