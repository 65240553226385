.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  // padding: 12px 16px;
  position: relative;
  border: none;
  color: var(--text-main);
  cursor: pointer;
  border-radius: 20px;
  height: 30px;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
  cursor: pointer;
  &:hover {
    background-color: var(--main-color)!important;
  }
}
