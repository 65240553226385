.container {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  z-index: 100;
  .uploadContainer {
    position: absolute;
    right: 30px;
    bottom: 0;
    height: 50px;
    width: 300px;
    background-color: var(--lighterbgColor);
    border-radius: 15px 15px 0 0;
    transition: all 0.3s ease-in-out;
    &.extended {
      height: 350px;
    }
    .upperSliderInteractiveSection {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 50px;
      border-radius: 15px 15px 0 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .header {
        font-size: 1rem;
        font-weight: 600;
        color: var(--text-main);
        margin-left: 10px;
        user-select: none;
      }
      .sliderActionContainer {
        cursor: pointer;
        outline: none;
        background-color: transparent;
        border: none;
        width: 50px;
        height: 100%;
        border-radius: 0 15px 0 0;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0.5;
        .sliderBtn {
          height: 10px;
          transition: all .3s ease-in-out;
        }
        &:hover {
          opacity: 1;
        }
      }
    }
    .uploadsContainer {
      position: absolute;
      left: 0;
      width: 100%;
      top: 50px;
      bottom: 0;
      padding: 10px;
      overflow-y: auto;
      overflow-x: hidden;
      color: var(--text-secondary);
      &::-webkit-scrollbar {
        height: 5px;
        width: 5px;
        opacity: hidden;
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px rgba(128, 128, 128, 0.233);
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background: rgba(128, 128, 128, 0.623);
        border-radius: 10px;
      }
      .uploadItem {
        padding: 12px 10px;
        margin: 2px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .uploadItemLeft {
          width: 100%;
          height: 30px;
          display: flex;
          flex-direction: column;
          position: relative;
          .nameContainer {
            font-size: 0.8rem;
            font-weight: 600;
            color: var(--text-main);
            user-select: none;
          }
          .progressContainer {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            padding-bottom: 10px;
            .progressBar {
              height: 2px;
              border-radius: 30px;
              background-color: var(--main-color);
              transition: all 0.3s ease-in-out;
              margin-right: 10px;
            }
            .errorMsg {
              color: #d43c2f;
              font-size: 0.6rem;
            }
          }
          .finalizing {
            position: absolute;
            right: 10px;
            top: 0;
            font-size: 0.6rem;
          }
        }
        .progress {
          font-size: 0.8rem;
          font-weight: 600;
          color: var(--text-main);
          user-select: none;
        }
        .progressResultLogo {
          height: 15px;
        }
        svg {
          height: 15px;
          width: 25px;
        }
        .uploadItemRight {
          width: 30px;
          height: 30px;
          .cancelBtn {
            color: var(--text-secondary);
            cursor: pointer;
            outline: none;
            background-color: transparent;
            border: none;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: 0.5;
            &:hover:not(:disabled) {
              opacity: 1;
            }
            &:disabled {
              opacity: 0.1;
              cursor: default;
            }
          }
        }
      }
    }
    .noDownloads {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
