.photo__img-container {
  position: relative;
  min-width: 100px;
  transition: all 0.3s ease-in-out;
  &.overflow {
    overflow: hidden;
  }
  .photo__overlay {
    position: absolute;
    top: 0;
    left: 10px;
    right: 10px;
    height: 100%;
    border-radius: 8px 8px 0 0;
    padding: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    transition: all 0.5s ease-in-out;
    .photo__title {
      font-size: 0.7rem;
      font-weight: 600;
      color: #c7c7c7;
      opacity: 0;
      transition: all 0.3s ease-in-out;
      padding-top: 7px;
    }
    .photo__icon-container {
      display: flex;
      height: 100%;
      flex-direction: row-reverse;
      flex-wrap: wrap;
      &.column {
        position: absolute;
        flex-direction: column;
        flex-wrap: wrap-reverse;
        right: 10px;
        top: 9px;
        gap: 5px;
        .photo__circle {
          z-index: 1;
        }
        .photo__circle {
          .tooltipInfo {
            width: max-content !important;
            left: 33px;
            top: 2px;
          }
        }
      }
      .photo__circle {
        opacity: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 5px;
        width: 30px;
        height: 30px;
        position: relative;
        border-radius: 50%;
        transition: all 0.3s ease-in-out;
        background: rgb(23, 23, 23);
        &.allways-visible {
          opacity: 0.7;
        }
        .tooltipInfo {
          position: absolute;
          top: -35px;
          left: 0;
          visibility: hidden;
          font-size: 10px;
          background-color: #1d1d1d;
          padding: 5px 10px;
          border-radius: 5px;
          z-index: 1;
          color: rgb(143, 143, 143);
        }
        &:hover {
          cursor: pointer;
          .tooltipInfo {
            visibility: visible;
          }
        }
      }
    }
  }
  &:hover {
    .photo__overlay {
      background: rgb(43, 43, 43);
      background: linear-gradient(180deg, #000000f2 0%, #ffffff00 100%);
      .photo__title {
        opacity: 1;
      }
      .photo__icon-container {
        .photo__circle {
          opacity: 0.7;
          &:hover {
            opacity: 1;
          }
        }
      }
    }
    .feautiring-container {
      opacity: 1;
    }
    .hide-hover {
      opacity: 0.5;
    }
  }
  -webkit-user-select: none; /* disable selection/Copy of UIWebView */
  user-select: none; /* standard property for compatibility */
  -webkit-touch-callout: none; /* disable the IOS popup when long-press on a link */
  &.vid {
    // transition: all 0.3s ease-in-out;
    // &:hover {
    //   transform: scale(1.08) !important;
    //   z-index: 99;
    // }
    video::-webkit-media-controls {
      display: none;
    }
  }
}

.photo__circle img {
  height: 50%;
  cursor: pointer;
  object-fit: cover;
}

.hide__edit__icon {
  display: none;
}

.photo__img {
  border-radius: 10px;
  image-rendering: optimizeSpeed;
  background-color: var(--barsColor);
  &.skeliton {
    animation: skeliton 1s linear infinite alternate;
    background: linear-gradient(
        90deg,
        var(--button-normal) 33%,
        var(--primary-btn-color) 50%,
        var(--lighterbgColor) 66%
      )
      var(--barsColor);
    background-size: 300% 100%;
  }
}

@keyframes skeliton {
  0% {
    background-position: right;
  }
}

.diaporama-progress {
  display: none;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 60%;
  height: 10px;
  background-color: var(--lighterbgColor);
  border-radius: 10px;
  .diaporama-progress__bar {
    position: absolute;
    bottom: 0px;
    left: -1px;
    top: 0px;
    border-radius: 10px 0 0 10px;
    background-color: var(--main-color);
  }
}

.feautiring-container {
  position: absolute;
  bottom: 15px;
  left: 20px;
  display: flex;
  align-items: center;
  z-index: 10;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  .feautiring-container__button {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1d1d1d;
    transition: all 0.3s ease-in-out;
    &.disabled {
      // loading cursor
      cursor: progress;
      svg {
        animation: progressing 1s ease-in-out infinite;
      }
    }
    svg {
      height: 50% !important;
      width: 50% !important;
      &.active path {
        fill: #c4c43a;
      }
    }
  }
}

.context-menu {
  display: none;
  position: fixed;
  z-index: 25;
  background-color: var(--barsColor);
  border-radius: 10px;
  transition: all 0.5s ease-in-out;
  .context-list {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    .context-item {
      color: var(--text-main);
      border-bottom: 1px solid var(--button-normal);
      padding: 20px 15px;
      transition: all 0.3s ease-in-out;
      &:hover {
        cursor: pointer;
        filter: invert(1);
      }
      &:first-child {
        border-radius: 10px 10px 0 0;
      }
      &:last-child {
        border-bottom: none;
        border-radius: 0 0 10px 10px;
      }
    }
  }
}

.react-contextmenu {
  background-color: var(--barsColor);
  border-radius: 10px;
  transition: all 0.5s ease-in-out;
  color: #373a3c;
  font-size: 16px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 250ms ease !important;
  overflow: hidden;
}

.react-contextmenu.react-contextmenu--visible {
  opacity: 1;
  pointer-events: auto;
  z-index: 9999;
}

.react-contextmenu-item {
  color: var(--text-main);
  border-bottom: 1px solid var(--button-normal);
  padding: 20px 15px;
  transition: all 0.3s ease-in-out;
  &:hover {
    cursor: pointer;
    filter: invert(1);
  }
  &:last-child {
    border-bottom: none;
  }
}

.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item.react-contextmenu-item--selected {
  color: var(--text-main);
  background-color: var(--container-blocks);
}

@keyframes progressing {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.hide-hover {
  position: absolute;
  bottom: 15px;
  left: 5px;
  right: 25px;
  background: transparent !important;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
  z-index: 0;
  svg {
    width: 25px;
  }
  .info-indicator {
    font-size: 0.8rem;
    font-weight: bold;
    color: var(--text-main);
    padding: 5px 6px;
    background-color: var(--barsColor);
    border-radius: 10px;
    &.p_icon {
      height: 25px;
      svg {
        height: 100% !important;
      }
    } 
  }
}

.processing_vid {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background-color: #00000037;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  padding: 10px;
  border-radius: 10px;
  font-size: 0.7rem;
  font-weight: bold;
  backdrop-filter: blur(5px);
}