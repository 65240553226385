.loader_container {
  position: absolute;
  width: 100%;
  top: 280px;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .loader {
    width: 53px;
    height: 75px;
    --g1: conic-gradient(from 90deg at left 3px top 3px, var(--main-color) 90deg, var(--main-color) 0);
    --g2: conic-gradient(
      from -90deg at bottom 3px right 3px,
      var(--main-color) 90deg,
      var(--main-color) 0
    );
    background: var(--g1), var(--g1), var(--g1), var(--g2), var(--g2), var(--g2);
    background-position: left center, center center, right center;
    background-repeat: no-repeat;
    animation: l9 1s infinite ease-in-out;
  }
  @keyframes l9 {
    0% {
      background-size: 15px 40%, 15px 40%, 15px 40%;
    }
    18% {
      background-size: 17px 55%, 15px 40%, 15px 40%;
    }
    50% {
      background-size: 15px 40%, 17px 55%, 15px 40%;
    }
    75% {
      background-size: 15px 40%, 15px 40%, 17px 55%;
    }
    100% {
      background-size: 15px 40%, 15px 40%, 15px 40%;
    }
  }
}
