.searchBarPosition {
  position: sticky;
  top: 0;
  height: 100%;
  width: 100%;
  // background-color: #0000004b;
  display: flex;
  align-items: center;
  padding: 10px 5%;
}

.selectWrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 150px;
  background-color: #212121;
  border-radius: 30px 0 0 30px;
  &.active {
    transition: all 0.1s ease-in-out;
    border-radius: 30px 0 0 0;
    .selectContainer {
      transition: all 0.3s ease-in-out;
      transform: scaleY(1);
    }
  }
  .selectButton {
    cursor: pointer;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    border: none;
    background-color: transparent;
    color: white;
    font-size: 0.9rem;
    font-weight: bold;
  }
  .selectContainer {
    cursor: pointer;
    transform: scaleY(0);
    transform-origin: top;
    position: absolute;
    top: 40px;
    left: 0;
    width: 100%;
    background-color: #212121;
    border-radius: 0 0 15px 15px;
    list-style-type: none;
    .selectItem {
      color: white;
      width: 100%;
      padding: 10px 0;
      text-align: center;
      background-color: #2c2c2c;
      font-size: 0.9rem;
      &:hover {
        background-color: #212121;
      }
      &:last-child {
        border-radius: 0 0 15px 15px;
      }
    }
  }
}

.input_wrapper {
  position: sticky;
  top: 15px;
  height: 40px;
  width: 100%;
  padding-left: 0px; /* 150px */
  .searchInput {
    height: 100%;
    width: 100%;
    padding: 5px 70px 5px 20px;
    outline: none;
    border: none;
    border-radius: 30px; /* 30px 0 0 30px */
    background-color: #5c5c5c40;
    color: var(--text-secondary);
    font-size: 0.9rem;
    backdrop-filter: blur(5px);
    &::placeholder {
      color: var(--text-secondary);
      opacity: 1;
    }
  }
  .searchButton {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 70px;
    background-color: transparent;
    border-radius: 0 30px 30px 0;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    .searchIcon {
      width: 18px;
    }
  }
}
