.header {
    text-align: center;
    padding: 5% 0;
    color: #cbcbcb;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
  }
  
  .title {
    font-size: 4.3rem;
    margin-bottom: 20px;
    background: linear-gradient(268deg, var(--main-color) 0%, var(--main-color-brighter) 50%, var(--main-color) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    font-weight: bolder;
    text-align: center;
  }
  
  .description {
    font-size: 1.2rem;
    text-align: center;
  }
  
  .image {
    max-width: 100%;
    height: auto;
    margin-top: 30px;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}