.container {
  display: flex;
  justify-content: center;
}

.container * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.faq__container {
  width: 100%;
  max-width: 1156px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  z-index: 100;
  margin-top: 144px;
  margin-bottom: 93px;
  color: var(--text-main);
  .faqList {
    text-align: start;
    ul,
    ol {
      text-align: start;
      padding-left: 15px;
      li {
        font-size: 15px;
        line-height: 19px;
        padding: 10px 0;
        color: var(--text-main);
      }
    }
    p,
    .desc {
      font-size: 16px;
      line-height: 22px;
      color: var(--text-secondary);
    }
    .desc {
      margin: 10px 0;
    }
  }
}

.faq__container > div {
  width: 100%;
  max-width: 1027px;
  font-family: Raleway, sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.faq__container > div > div:nth-child(1) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 10px;
}

.faq__container > div > div:nth-child(1) h1 {
  font-family: Raleway, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0;
}

.faq__container > div > div:nth-child(1) h2 {
  font-family: Raleway, sans-serif;
  font-size: 36px;
  font-weight: 600;
  line-height: 42px;
  letter-spacing: 0;
  margin-bottom: 50px;
}

.faq__container > div > div:nth-child(2) {
  width: 100%;
  max-width: 1027px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.faq__container__question__wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 14px;
}

.faq__container__question__wrapper div:nth-child(1) {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--text-secondary);
  font-size: 22px;
}

.faq__container__question__wrapper div:nth-child(1) > p,
i {
  font-size: 22px;
  line-height: 43px;
}

.faq__container__question__wrapper div:nth-child(2) {
  text-align: justify;
  border-bottom: 1px solid var(--text-secondary);
  padding-bottom: 5px;
  //margin-top: 50px;
}

.faq__container__question__wrapper div:nth-child(3) {
  width: 100%;
  max-width: 1027px;
  border-bottom: 1px solid var(--text-secondary);
  margin-bottom: 14px;
}

.faq__container__question__answer {
  display: none;
}

.q_contianer {
  align-items: center;
  svg {
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;
    &:hover {
      opacity: 1;
    }
  }
}
