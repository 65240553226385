.folderCard {
  width: 25%;
  max-width: 300px;
  min-width: 250px;
  background-color: var(--button-normal);
  border: 1px solid transparent;
  position: relative;
  cursor: pointer;
  border-radius: 15px;
  opacity: 0.8;
  aspect-ratio: 16 / 9;
  .folderContainer {
    height: 100%;
    width: 100%;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 5% 10%;
  }
  .dragOver {
    border-radius: 15px;
    background-color: var(--lighterbgColor) !important;
    border: 1px dotted var(--text-third) !important;
  }
  .folderControlContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .folderThumbnail {
      height: 80px;
      border-radius: 10px;
      background-color: var(--lighterbgColor);
    }
  }
  .controlIcon {
    font-size: 1.5rem;
    line-height: 20px;
    padding: 5px;
    color: var(--text-secondary);
    word-spacing: 2px;
    position: absolute;
    top: 10px;
    right: 20px;
    z-index: 1;
  }
  .folderInfo {
    .folderName {
      padding: 12px 0 5px 0;
      font-size: 1rem;
      font-weight: 600;
      color: var(--text-main);
      .folderCount {
        font-size: 0.9rem;
        font-weight: 400;
      }
    }
    .lastUpdated {
      font-size: 0.8rem;
      font-weight: 400;
      color: var(--text-third);
    }
  }
  &:hover {
    opacity: 1;
  }
}

@media (max-width: 500px) {
    .folderCard {
      width: 100% !important;
      max-width: 100% !important;
      min-width: 100% !important;
      aspect-ratio: unset !important;
      height: 150px !important;
    }
}