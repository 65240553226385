.spinner {
    position: relative;
    svg {
    }
}

.load {
    position: absolute;
    top: 0;
    left: -1px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
