.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: white;
  padding: 30px 20px 20px 20px;
  .header {
    height: calc(100% - 60px);
    display: flex;
    align-items: center;
    .title {
      font-size: 1.3rem;
      text-align: center;
      font-weight: bold;
      margin-bottom: 10px;
      color: var(--text-secondary);
    }
  }
  .buttonsArea {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .deleteContainerRight,
    .cancelContainerLeft {
      button {
        background-color: rgb(77, 77, 77);
        padding: 10px 20px;
        border-radius: 10px;
        color: white;
        font-size: 0.9rem;
        font-weight: bold;
        opacity: 0.6;
        cursor: pointer;
        &:hover {
          opacity: 1;
        }
      }
    }
    .deleteContainerRight {
      button {
        background-color: var(--main-color);
        margin-left: 15px;
      }
    }
  }
}
