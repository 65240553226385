.button {
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px 25px;
  background-color: var(--primary-btn-color) !important;
  color: var(--text-secondary) !important;
  border: none;
  border-radius: 30px;
  font-size: 14px;
  font-weight: 300;
  cursor: pointer;
  transition: all .3s ease-in-out;
  svg{
    max-width: 20px;
  }
  &:hover:not(:disabled) {
    background-color: var(--main-color-brighter) !important;
    color: white !important;
    font-weight: 500;
    transform: scale(1.03);
  }
  &:focus {
    border: none;
    outline: none;
  }
}

button:disabled {
   cursor: default;
    &:hover {
    background-color: var(--primary-btn-color);
    .disableMsg {
      transform: scaley(1);
    }
  }
}

.disableMsg {
  position: absolute;
  top: calc(100% + 10px);
  right: 20px;
  width: max-content;
  padding: 5px;
  background-color: var(--main-color-brighter);
  color: var(--text-main);
  border-radius: 5px;
  transform: scaley(0);
  transform-origin: top;
  transition: all .3s ease-in-out;
}