.container {
  height: 100%;
  width: 100%;
  color: var(--text-main);
  padding: 60px 100px;
  .closeIconButtonContainer {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    .closeIconButton {
      color: var(--text-main);;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0.8;
      background-color: inherit;
      background-color: var(--button-normal);
      &:hover {
        opacity: 1;
        filter: invert(1);
      }
    }
  }
  .folderSelectColorContainer,
  .headerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
    .headerText {
      font-size: 20px;
      line-height: 23px;
      font-weight: normal;
      margin: 15px;
    }
  }
  .folderInput {
    margin-bottom: 15px;
    width: 100%;
    background: var(--button-normal);
    border-radius: 100px;
    padding: 15px 20px;
    border: none;
    outline: none;
    font-weight: 400;
    font-size: 1rem;
    color: var(--text-main);
    line-height: 19px;
  }

  .folderSelectColorContainer {
    .colorContainer {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      list-style: none;
      gap: 10px;
      width: 100%;
      .color {
        width: 40px;
        height: 40px;
        border-radius: 10px;
        cursor: pointer;
        &:hover,
        &.selected {
          transform: scale(1.1);
        }
      }
    }
  }

  .submitBtnContainer {
    width: 100%;
    padding: 40px 0 15px 0;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .submitBtn {
    width: 100%;
    background: var(--main-color);
    border-radius: 100px;
    padding: 15px 20px;
    border: none;
    outline: none;
    font-weight: 400;
    font-size: 1rem;
    color: var(--text-main);
    line-height: 19px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &.deleteBtn {
      background: #9f2010 !important;
      &:hover {
      background: #521108 !important;
      }
    }

    &:hover {
      background: var(--main-color-brighter);
      &:not(:disabled) {
        transform: scale(1.02);
      }
    }
    &:disabled {
      background-color: var(--lighterbgColor);
      opacity: 0.5;
      cursor: default;
    }
  }
  .extraInfo {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    padding-top: 15px;
    text-align: center;
  }
}

@media (max-width: 800px) {
  .container {
    padding: 60px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .submitBtnContainer,
  .inputContainer {
    width: 100%;
    max-width: 400px;
  }
  .folderInput {
    min-width: 100%;
  }
}