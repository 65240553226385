.container {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 5%;
  .searchbar {
    position: relative;
    border-radius: 10px;
    background: var(--filtercolorgradient);
    height: 45px;
    display: flex;
    max-width: 900px;
  }
  .search_asset_btn_container {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 20%;
    border-radius: 10px 0 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 2;
    .search_asset_btn {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: inherit;
      gap: 10px;
      background-color: var(--main-color);
      opacity: 1;
      color: var(--text-main);
      font-size: 1rem;
      &:hover {
        opacity: 0.8;
      }
      svg {
        height: 100%;
      }
    }
    .assetSelector_list_container {
      position: absolute;
      top: 100%;
      left: 0;
      max-height: 0;
      width: 100%;
      background-color: var(--main-color);
      border-radius: 0 0 10px 10px;
      display: flex;
      flex-direction: column;
      gap: 2px;
      list-style-type: none;
      padding: 0;
      overflow-y: auto;
      overflow-x: hidden;
      transition: all 0.05s ease-in-out;
      .assetSelectorItem {
        width: 100%;
        padding: 10px 0;
        font-size: 1rem;
        color: var(--text-main);
        text-align: center;
        &:hover {
          background-color: var(--main-color-brighter);
        }
      }
    }
    &.active_menu {
      border-radius: 10px 0 0 0 !important;
      .search_asset_btn {
        opacity: 0.8 !important;
      }
      .assetSelector_list_container {
        max-height: 200px;
      }
    }
  }
  .input_search_container {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    left: 20%;
    width: calc(80% - 60px);
    z-index: 2;
    backdrop-filter: blur(20px);
    input {
      width: 100%;
      height: 100%;
      border: none;
      outline: none;
      background: transparent;
      color: var(--text-main);
      padding: 0 10px;
      font-size: 1rem;
      &::placeholder {
        color: var(--text-secondary);
      }
    }
    &.onlySearch {
      width: calc(100% - 60px) !important;
      left: 0 !important;
      border-radius: 10px 0 0 10px;
    }
  }
  .filter_more_btn_container {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(20px);
    cursor: pointer;
    border-radius: 0 10px 10px 0;
    padding: 5px 10px;
    z-index: 2;
    .filter_more_btn {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      &:hover,
      &.active_filter {
        background-color: var(--main-color);
      }
      svg {
        height: 100%;
      }
    }
  }

  .dropdownContainer {
    backdrop-filter: blur(20px);
    transform: scaley(0);
    transform-origin: top;
    border-radius: 0 0 30px 30px;
    background: inherit;
    margin-top: 40px;
    padding-bottom: 10px;
    height: 300px;
    width: 100%;
    transition: all 0.05s ease-in-out;
    z-index: 1;
    &.activeMenu {
      transform: scaley(1);
      // border-top-left-radius: 30px;
      // border-top-right-radius: 30px;
    }
    .filterContainer {
      color: white;
      padding: 10px 20px;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      width: 100%;
      height: 100%;
      overflow-x: hidden;
      overflow-y: scroll;
      justify-content: space-between;
      user-select: none;
      .filterCell {
        height: 100%;
        display: flex;
        flex-direction: column;
        transition: all 0.3s ease-in-out;
        max-width: 150px;
      }
      .filterHeader {
        margin: 5px 0 10px 0;
        height: 10%;
        font-size: 1rem;
        font-weight: bolder;
        color: white;
        opacity: 0.5;
        width: 100%;
      }
      .cellOptions {
        height: 100%;
        width: 100%;
        overflow-y: auto;
        overflow-x: none;
        color: white;
        span {
          font-weight: 500;
        }
        * span,
        p {
          font-size: 0.9rem;
          margin: 10px 0;
        }
        .colorContainer {
          display: flex;
          flex-wrap: wrap;
          .box {
            width: 20px;
            height: 20px;
            border-radius: 5px;
            margin: 3px 3px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
    .applyBtnContainer {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 10px;
      height: 30px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .applyBtn {
        background-color: var(--main-color);
        border-radius: 20px;
        padding: 10px 20px;
        color: white;
        font-weight: bold;
        font-size: 0.9rem;
        border: none;
        margin-right: 10px;
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
  &::-webkit-scrollbar {
    width: 0 !important;
  }
}

// // media querry for less than 1100px

// @media (max-width: 1100px) {
//   .container {
//     .searchbar {
//       width: 90% !important;
//     }
//     .dropdownContainer {
//       // overflow: hidden;
//       .filterContainer {
//         display: grid !important;
//         //gap top 10px
//         grid-template-columns: repeat(auto-fill, minmax(150px, 25%));
//         height: 100%;
//         gap: 10px;
//         justify-content: space-around !important;
//         * ::-webkit-scrollbar {
//           width: 0 !important;
//         }
//         .filterCell {
//           max-height: 150px;
//           &:hover {
//             max-height: 100% !important;
//           }
//         }
//       }
//     }
//   }
// }
