@import "../../../../assets/scss/mixins";

.container * {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.container {
  width: 100%;
  height: 100%;
}

.select__resources__button {
  background-color: var(--button-normal);
  padding: 10px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.select__resources__tempGrid {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(3, 1fr);
}

.select__resources__headerContent {
  margin-bottom: 35px;
  margin-left: 8px;
  color: var(--text-main);
  width: 70%;
  h4{
    font-size: 18px;
    margin-bottom: 8px;
  }
}

.select__resources__footerSection {
  border-top: 1px solid var(--button-normal);
  bottom: 15px;
  position: relative;
  width: 100%;
  display: flex;
  gap: 15px;
  justify-content: flex-end;
  margin-top: 2rem;
  padding-top: 1rem;
}

.select__resources__close__modal__btn {
  display: flex;
  background-color: var(--button-normal);
  border: none;
  color: var(--text-main);
  position: absolute;
  right: 2%;
  top: 2%;
  cursor: pointer;
}

@media (max-width: 800px) {
  .select__resources__tempGrid {
    grid-template-columns: repeat(2, 1fr) !important;
    gap: 20px !important;
  }
  .select__resources__footerSection {
    margin-left: 0;
  }
}