main {
    color: var(--text-main);
}

._container {
    position: inherit;
    width: 100%;
    height: 100%;
}

.header {
    width: 80%;
    min-height: 100px;
    margin: auto;
    background-color: var(--text-secondary);
    border-radius: 0.2rem;
    margin-top: 1rem;
    padding: 1rem;
}

.content-section {
    display: flex;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.content-section div {
    padding: 0.5rem;
    box-sizing: border-box;
}

.button {
    background-color: var(--main-color);
    margin-right: 1rem ;
}

.footer-section {
    
    bottom: 15px;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 1.5rem;
    padding-top: 1rem;
    margin-left: 20px;
}

.temp-grid {
    display: grid;
    gap: 1.5rem;
    width: 70%;
    grid-template-columns: repeat(3, 1fr);
    margin: auto;
}

.header-content {
    color: var(--text-main);
    width: 75%;
    margin: auto;
}

.buttonInput{
    margin-top: 37px;
}