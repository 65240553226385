.container {
  background-repeat: no-repeat;
  background-size: 30%, 30%;
  background-color: #ececec;
  background-position: 105% -5%, -5% 105%;
  min-height: 700px;
}

.creative__space__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 20px;
  div {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      color: #141414;
      font-family: Raleway, sans-serif;
      font-size: 3.125rem;
      font-style: normal;
      font-weight: 700;
      line-height: 100%; /* 50px */
      letter-spacing: 0.009rem;
      max-width: 625px;
    }
  }

  figure {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 530px;
    width: 100%;
    max-width: 1216px;
    img {
      padding: 30px 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

}

.creative__space__container__button {
  width: 150px;
  height: 46px;
  background-color: var(--mainBgColor);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

// media querry less than 450px

@media (max-width: 450px) {
  .container {
    background-size: 60%, 60%;
  }
  .creative__space__container {
    background-size: 250px 250px;
    padding: 40px 20px;
      p {
        font-size: 1.9rem !important;
      }
      figure {
        height: 400px !important;
        padding: 40px 0;
      }
      img {
        padding: 0;
      }
  }

  .creative__space__container__button {
    width: 150px;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  }
}


@media (min-width: 2000px){
  .creative__space__container {
    min-height: calc(100vh - 200px) !important;
  }
}