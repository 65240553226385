.collectionContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  color: var(--text-main);
  background-color: var(--mainBgColor);
  border-radius: 20px;

  .collectionContent{
    padding: 30px 40px 50px 40px;
    width: 100%;
    height: 100%;
  }

  .banner{
    width: 100%;
    max-width: 250px;
    height: 100%;
    background-image: url(https://publisher-frontend-prod-data.s3.us-west-1.amazonaws.com/mpublisher/Group_40538_hogamw.png);
    background-position: center;
    background-size: cover;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    h2 {
      padding: 10px 20px;
      margin: 10px 0;
      font-weight: 500;
      line-height: 43px;
      font-size: 30px;
    }
  }
  .input {
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    border-radius: 15px;
    background-color: var(--lighterbgColor);
    color: var(--text-main);
    font-size: 1rem;
    padding: 5px 30px 5px 25px;
  }
  .createCollectionContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 80px;
    .createCollectionInputContainer {
      position: relative;
      height: 70px;
      width: 100%;
      padding: 10px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      .input {
        border-radius: 15px;
      }
      button {
        position: absolute;
        top: 10px;
        bottom: 10px;
        right: 5px;
        width: 50px;
        border-radius: 20px;
        border: none;
        background-color: none;
        display: flex;
        justify-content: center;
        align-items: center;
        svg{
          width: 23px;
          zoom: .7;
          margin-right: 11px;
          polygon{
            fill: var(--text-third);
          }
          &:hover{
            g{
              polygon{
                fill: var(--text-main);
              }
            }
          }
        }
        img {
          height: 40%;
        }
      }
    }
  }
  .isPublicSelector {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-top: 15px;
    width: 100%;
    .headerSpan {
      font-size: 1.2rem;
      margin: 10px 0;
    }
    .isPublicSelector_radioContainer {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 5px 0;
      user-select: none;
      cursor: pointer !important;
      label {
        cursor: pointer !important;
      }
      .customRadio {
        height: 15px;
        width: 15px;
        border-radius: 50%;
        border: 1px solid white;
        margin-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        &.active {
          border-color: var(--main-color);
        }
        .customRadio_inner {
          height: 100%;
          width: 100%;
          border-radius: 50%;
          font-size: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: var(--main-color);
        }
      }
    }
  }
  .allCollectionShowcaseContainer {
    width: 100%;
    height: calc(100% - 160px);
    margin-top: 30px;
    padding-bottom: 10px;
    border: 1px solid var(--mainBgColor-opacity-shadow);
    background-color: var(--button-normal);
    border-radius: 15px;
    position: relative;
    .searchInputContainer {
      position: absolute;
      top: -1px;
      left: 0px;
      right: 0px;
      height: 50px;
      border-radius: 15px;
      overflow: hidden;
      input {
        padding: 5px 80px 5px 25px;
        background-image: url("../../../../../assets/icons/searchmag.svg");
        background-repeat: no-repeat;
        background-position: 95% 50%;
        background-size: 20px;
      }
      .searchButton {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 70px;
        background-color: transparent;
        border-radius: 0 30px 30px 0;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        .searchIcon {
          width: 18px;
        }
      }
    }
    .showcaseContainer {
      height: 100%;
      width: 100%;
      overflow: hidden;
      .allCollectionShowcaseContainer_list {
        margin-top: 60px;
        height: calc(100% - 55px);
        width: 100%;
        border-radius: 0 0 30px 30px;
        padding: 0 10px;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        overflow-x: hidden;
        &::-webkit-scrollbar-track {
          margin-bottom: 30px;
        }
        .allCollectionShowcaseContainer_list_item {
          padding: 20px 10px;
          margin: 1px 0;
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .collection_dataInfo {
            display: flex;
            align-items: center;
            img {
              height: 40px;
              width: 40px;
              margin: 0 10px 0 0;
            }
          }
          .btnInteractionContainer {
            .deselectBtn {
              height: 22px;
              width: 22px;
              border-radius: 50%;
              border: none;
              background-color: var(--button-normal);
              color: var(--text-main);
              font-size: 0.7rem;
              cursor: pointer;
              &:hover {
                background-color: var(--main-color);
              }
            }
          }
          &:hover {
            background-color: var(--mainBgColor-opacity-shadow);
          }
          &.active {
            background-color: var(--barsColor);
          }
        }
        .noCollectionContainer {
          height: 100%;
          min-height: 120px;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: var(--text-third);
        }
      }
    }
  }
  .saveButtonContainer {
    width: 100%;
    margin-top: 40px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    button {
      padding: 10px 25px;
      border-radius: 30px;
      border: none;
      background-color: var(--main-color);
      color: white;
      font-size: .8rem;
      font-weight: 600;
      cursor: pointer;
      &:hover {
        background-color: var(--main-color-brighter);
      }
    }
  }
}

.addnewTempCollection{
  svg{
    zoom: .7;
  }
}

// media querry less than 800px

@media (max-width: 800px) {
  .collectionContainer {
    flex-direction: column;
  }
  .banner {
    max-width: unset !important;
    height: 90px !important;
    display: flex;
    align-items: center;
    border-radius: 16px 16px 0px 0 !important;
  }

  .collectionContent {
    height: calc(100% - 70px) !important;
  }
}