.container {
  width: 100%;
  height: 100%;
  display: flex;
  margin-top: 8%;
  padding: 0 10px;
  flex-direction: column;
  color: var(--text-main);
  font-family: "Raleway", sans-serif;
  .title {
    color: var(--text-main);
    font-size: 24px;
    font-weight: bold;
  }
  .text {
    color: var(--text-secondary);
    font-size: 14px;
    margin: 20px 0;
  }
  .instructions__container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 0 0 0 45px;
    color: var(--text-secondary);
  }
  .go_button {
    margin: 50px 0;
    max-width: 160px;
    padding: 15px 0;
    border-radius: 25px;
    background: linear-gradient(90deg, var(--main-color) 0%, var(--main-color-brighter) 100%);
    color: #dddddd;
    text-decoration: none;
    font-size: 1rem;
    font-weight: bold;
    display: flex;
    justify-content: center;
    opacity: .8;
    transition: all .2s ease-in-out;
    &:hover {
      opacity: 1;
      transform: scale(1.02);
    }
  }
}