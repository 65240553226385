.uploadContentContainer {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  .InfromationSection {
    position: relative;
    width: 100%;
    padding: 40px 5% 20px 5%;
  }
  .interactiveSection {
    height: 100%;
    width: 100%;
    padding: 10px 5% 20px 5%;
    overflow-y: auto;
    overflow-x: hidden;
  }
}