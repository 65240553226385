.formContainer {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  label {
    font-size: 0.8rem;
    font-weight: bold;
    padding-bottom: 5px;
  }
  .projectInputs,
  .upperInputs {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
    margin: 10px 0;
    .inputWrapper {
      height: 100%;
      width: 40%;
      max-width: 600px;
      display: flex;
      flex-direction: column;
      label{
        padding-bottom: 10px;
      }
      .input {
        height: 100%;
        border-radius: 10px;
        border: none;
        outline: none;
        padding: 0 20px;
        background: var(--primary-btn-color);
        color: var(--text-secondary);
        &::placeholder {
          color: var(--text-secondary);
          font-size: 0.7rem;
        }
      }
    }
    .selectorContainer {
      width: 40%;
      max-width: 600px;
      display: flex;
      flex-direction: column;
      position: relative;
      .labling_type_container {
        display: flex;
        justify-content: space-between;
        height: 25px;
        z-index: 10;
        .folder_type_sect {
          font-size: 0.8rem;
          margin-top: -4px;
          display: flex;
          align-items: center;
          gap: 10px;
          .folder_type_btn {
            padding: 5px 10px;
            margin-bottom: 5px;
            border-radius: 15px;
            background-color: var(--primary-btn-color);
            color: var(--text-main);
            opacity: 0.8;
            transition: all 0.2s ease-in-out;
            font-size: 0.7rem;
            position: relative;
            &:hover:not(.active) {
              opacity: 1;
              transform: scale(1.02);
            }
            &.active {
              background-color: var(--barsColor);
              color: var(--text-main);
              opacity: 1;
            }
            .disableMsg {
              position: absolute;
              top: calc(100% + 10px);
              right: 20px;
              width: max-content;
              padding: 5px;
              background-color: var(--main-color-brighter);
              color: var(--text-main);
              border-radius: 5px;
              transform: scaley(0);
              transform-origin: top;
              transition: all 0.3s ease-in-out;
            }
            &:disabled {
              opacity: 0.5;
              &:hover {
                transform: scale(1) !important;
                opacity: 0.5 !important;
                .disableMsg {
                  transform: scaley(1);
                }
              }
            }
          }
        }
        label {
          font-size: 0.8rem;
          padding: 0;
        }
      }
      .selector {
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        border: none;
        outline: none;
        padding: 0 20px;
        max-width: 600px;
        background: var(--primary-btn-color);
        color: var(--text-secondary) !important;
        font-size: 0.7rem;
        &::after {
          position: absolute;
          content: "";
          top: 18px;
          right: 10px;
          width: 10px;
          height: 10px;
          background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAALCAYAAACd1bY6AAAAn0lEQVQokZ3RMQ4BURAG4G9f1BrRcgIVJ9i4AXdxBu7gBpqNFofQUqlcQTQKbxPZvH2saWaS+fMlkymqqlpiiymu/qsdQsADfVww6YgUOGKBQcAeq7g8dwALHFDijnmIi01HsAmN8AwfgV/BJAShEfwGtkIpLAf2clAdSNUm9nUET5h5fz0J5bAmWMa5FSJ9ZhOsT77loF+wGhxinIPgBW9HLVYNb9jfAAAAAElFTkSuQmCC");
          background-repeat: no-repeat;
          background-size: contain;
        }
        span {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          height: 100%;
        }
        .dropDownMenu {
          position: absolute;
          top: 35px;
          left: 0;
          width: 100%;
          max-height: 150px;
          background-color: inherit;
          border-radius: 0 0 10px 10px;
          display: flex;
          flex-direction: column;
          overflow-y: auto;
          overflow-x: none;
          height: 0;
          &.active {
            height: auto;
            padding: 10px 0;
            transition: all 0.3s ease-in-out;
            &.folder_selector {
              .mobile_folder_type {
                display: none;
              }
            }
          }
          .folder_list {
            height: calc(100% - 40px);
            overflow-y: auto;
            overflow-x: none;
          }
          li {
            font-size: 0.7rem;
            padding: 10px 20px;
            cursor: pointer;
            &:hover {
              background-color: var(--primary-btn-color);
              filter: contrast(0.8);
            }
          }
        }
      }
    }
  }

  .projectInputs {
    margin: 0;
    height: 120px;
    .inputWrapper {
      width: 100%;
      max-width: unset;
      .input {
        display: none;
      }
      .customup {
        height: 100%;
        border: 1px dotted var(--text-secondary);
        border-radius: 10px;
        padding: 6px 12px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        .removeProject {
          width: 20px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          background-color: var(--main-color-brighter);
          color: var(--text-main);
          font-size: 0.8rem;
          opacity: 0.8;
          cursor: pointer;
          transition: all 0.2s ease-in-out;
          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }
  .tagsContainer {
    background-color: var(--primary-btn-color);
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    height: 100px;
    margin: 10px 0;
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
  }
  .filterContainer {
    margin: 32px 0;
    background-color: var(--primary-btn-color);
    width: 100%;
    padding: 10px 20px;
    border-radius: 10px;
    height: 275px;
    display: flex;
    justify-content: space-between;
    user-select: none;
    .filterCell {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      margin: 0 5px;
      &.grayed {
        opacity: 0.5;
        pointer-events: none;
      }
    }
    .filterHeader {
      margin: 5px 0 10px 0;
      height: 10%;
      font-weight: bold;
      color: var(--text-secondary);
      font-size: 14px;
      width: 100%;
    }
    .cellOptions {
      height: 100%;
      width: 100%;
      overflow-y: auto;
      overflow-x: none;
      color: var(--text-third);
      * span,
      p {
        font-size: 1rem;
        margin: 5px 0;
      }
      .colorContainer {
        display: flex;
        flex-wrap: wrap;
        .box {
          width: 20px;
          height: 20px;
          border-radius: 5px;
          margin: 3px 3px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  .contentArea {
    width: 100%;
    height: 100px;
    margin: 10px 0;
    textarea {
      padding: 10px 20px;
      width: 100%;
      height: 100%;
      background-color: var(--primary-btn-color);
      padding: 10px 20px;
      border-radius: 10px;
      border: none;
      outline: none;
      resize: none;
      color: var(--text-secondary);
    }
  }
}

// media querry less than 800px
@media (max-width: 800px) {
  .formContainer {
    &::-webkit-scrollbar {
      width: 0 !important;
      height: 0 !important;
    }
    .filterContainer {
      flex-wrap: wrap;
      height: unset !important;
      .filterCell {
        max-width: 120px !important;
      }
    }
    .folder_selector {
      overflow-y: unset !important;
      overflow-x: unset !important;
      border-radius: 0;
      .mobile_folder_type {
        position: absolute;
        top: calc(100% - 2px);
        left: 0;
        right: 0;
        height: 40px;
        border-radius: 0 0 10px 10px;
        overflow: hidden;
        display: flex !important;
        .folder_type_btn_mob {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: var(--lighterbgColor);
          color: var(--text-main);
          transition: all 0.2s ease-in-out;
          font-size: 0.7rem;
          &:hover:not(.active) {
            opacity: 1;
          }
          &.active {
            background-color: var(--barsColor);
          }
          &:disabled {
            color: var(--text-third);
          }
        }
      }
    }
    .folder_type_sect {
      display: none !important;
    }
  }
}

@media (max-width: 500px) {
  .upperInputs {
    flex-direction: column !important;
    height: unset !important;
    gap: 10px;
    margin: 0 !important;
    .inputWrapper {
      width: 100% !important;
      max-width: unset !important;
      height: 70px !important;
    }
    .selectorContainer {
      width: 100% !important;
      max-width: unset !important;
      .selector {
        height: 45px !important;
      }
    }
  }
}