.files__container {
  height: 100%;
  width: 100%;
  background-color: var(--mainBgColor);
  overflow-y: auto;
  overflow-x: hidden;
}

.search_container {
  position: sticky;
  top: -220px;
  height: 300px;
  z-index: 20;
}

.tags_container {
  padding: 20px 5%;
  position: sticky;
  margin-top: 10px;
  z-index: 1;
  background-color: var(--mainBgColor);
}

.content_container {
  // padding: 10px 0;
  padding: 20px 5%;
  z-index: 0;
  display: flex;
  flex-direction: column;
  .content_container__title {
    font-size: 1.5rem;
    font-weight: 500;
    color: var(--text-main);
  }
  .content_container__count {
    font-size: 1rem;
    font-weight: 400;
    color: var(--text-secondary);
    margin-bottom: 25px;
  }

  .folders_wraper {
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    z-index: 5;
    .addBtn {
      width: 25%;
      max-width: 300px;
      min-width: 250px;
      background-color: var(--button-normal);
      border: 1px solid transparent;
      position: relative;
      cursor: pointer;
      border-radius: 15px;
      padding: 30px 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0.8;
      text-decoration: none;
      aspect-ratio: 16 / 9;
      transition: all 0.2s ease-in-out;
      &:hover {
        opacity: 1;
      }
    }
  }
}

// media querry less than 500px
@media (max-width: 500px) {
  .addBtn {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
    aspect-ratio: unset !important;
    height: 150px !important;
  }
}