.template__grid__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  aspect-ratio: 16 / 9;
  .top {
    grid-area: 1 / 1 / 2 / 2;
  }
  .bottom {
    grid-area: 2 / 1 / 3 / 2;
  }
  .template_spanned {
    grid-area: 1 / 2 / 3 / 3;
    padding-right: 25px;
  }

  img {
    width: 100%;
  }
}