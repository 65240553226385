.containerWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  .upperControlcontainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 300px;
    padding: 10px 5%;

    .actionsContainer {
      display: flex;
      position: relative;
      margin-top: 1rem;
      margin-bottom: 5rem;
      justify-content: space-between;
      align-items: center;
      .items{
        color: var(--text-secondary);
        font-size: 18px;
      }
      .actionButtons {
        display: flex;
        flex-direction: row;
        align-items: center;
        .actionButtonsSegmentOne {
          padding-right: 1rem;
          .btnContainer {
            display: flex;
            gap: 10px;
            button {
              background-color: var(--button-normal);
              font-weight: normal;
              padding: 18px;
              color: var(--text-secondary);
              opacity: .8;
              &:hover{
                opacity: 1;
              }
            }
            .grayText {
              color: var(--text-third);
            }
          }
        }
        .actionButtonsSegmentTwo {
          display: flex;
          .iconOne {
            padding-right: 10px;
          }
        }
      }
    }
  }
  .contentArea {
    position: absolute;
    top: 300px;
    left: 0;
    width: 100%;
    bottom: 0;
    padding: 10px 5%;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;

    .ImgsContainer {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      justify-content: space-between;
      gap: 20px;
      .singleImgContainer {
        user-select: none;
        position: relative;
        height: 100%;
        border-radius: 10px;
        box-sizing: content-box;
        display: flex;
        flex-direction: column;
        img {
          border-radius: 10px;
          aspect-ratio: 16/9;
          object-fit: cover;
        }
        .imgChckbox {
          position: absolute;
          top: 5px;
          right: 5px;
          cursor: pointer;
        }
        .deleteimgBtn {
          position: absolute;
          top: 5px;
          right: 5px;
          opacity: 0.4;
          transition: all 0.3s ease-in-out;

          button {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--button-normal);
            border: none;
            height: 20px;
            width: 20px;
            font-size: 20px;
            padding-bottom: 3px;
            border-radius: 50%;
            color: var(--text-main);
          }
          &:hover {
            opacity: 1;
          }
        }

        .ellipsisContainer {
          display: flex;
          justify-content: flex-end;
        }

        .ellipsis {
          font-size: 18px;
          line-height: 1;
          vertical-align: middle;
          color: var(--text-main);
          display: flex;
          justify-content: flex-end;
          padding: 10px 0 0 5px;
          cursor: pointer;
        }

        .menu {
          position: absolute;
          top: 100%;
          right: 20px;
          z-index: 1;
          background-color: var(--button-normal);
          border-radius: 5px;
          padding: 5px;
          color: var(--text-main);
          transform: scale(0);
          transform-origin: top right;
          transition: all 0.3s ease-in-out;
          &.active {
            transform: scale(1);
          }
        }

        .menu li {
          padding: 8px 10px;
          list-style-type: none;
          cursor: pointer;
          &:hover {
            background-color: var(--main-color);
            color: #fff !important;
          }
        }

        /* Hide menu when ellipsis is not clicked */
        // .ellipsisContainer:not(:hover) .menu {
        //   display: none;
        // }
      }
      .noImgs {
        width: 100%;
        text-align: center;
      }
    }

    .contentContainer {
      $gray: var(--text-secondary);
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: absolute;
      height: 100%;
      top: -50px;
      left: 0;
      right: 0;

      .trashCaption {
        padding-top: 1rem;
        text-align: center;
      }

      .bold {
        font-weight: bold;
        font-size: 23px;
        color: var(--text-secondary);
      }

      .grayText {
        color: $gray;
        font-size: 17px;
        line-height: 25px;
        padding-top: .5rem;
        font-weight: lighter;
      }
    }
  }
}

.spinnerContainer{
  position: absolute;
  top: 300px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
