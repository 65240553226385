.container * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.asset__links__container {
  color: var(--text-main);
  min-height: calc(100vh - 420px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px 20px;
  gap: 60px;

  h1 {
    color: var(--text-main);
    text-align: center;
    font-family: Raleway, sans-serif;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 40px */
    letter-spacing: 0.008rem;
  }
}

.asset__links__listing {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  gap: 40px;
}

.listing_card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  max-width: 407px;
  
  img {
    width: 100%;
    object-fit: contain;
    margin-bottom: 30px;
  }

  p {
    color: var(--text-main);
    font-family: Raleway, sans-serif;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 24px */
    letter-spacing: 0.005rem;
    margin-bottom: 20px;
  }
  small {
    color: var(--text-secondary);;
    font-family: Raleway, sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 150% */
    letter-spacing: -0.014rem;
    max-width: 337px;
    margin-bottom: 30px;
  }
}
.asset__links__container div div button:first-of-type {
  width: 94px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 0.003em;
  text-align: center;
}

.asset__links__button {
  padding: 20px 30px;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  border: 1px solid var(--text-third) !important;
  width: 100% !important;
  max-width: 190px !important;
  transition: all 0.3s ease-in-out;
  color: var(--text-secondary) !important;
  background-color: transparent !important;
  &:hover {
    border: 1px solid transparent !important;
    color: var(--text-main) !important;
    background-color: var(--main-color-brighter) !important;
  }
}

@media (max-width: 1100px) {
  .asset__links__container {
    height: unset !important;
    padding: 70px 50px;
  }
  .asset__links__listing {
    flex-wrap: wrap;
  }
  .listing_card {
    max-width: 70%;
    min-width: 250px;
    align-items: center;
    text-align: center !important;
  }
}

// media querry between 1600 and 1100px

@media (min-width: 1100px) and (max-width: 1600px) {
  .asset__links__container {
    min-height: calc(100vh - 100px);
    padding: 20px 50px !important;
    gap: 130px !important;
  }
}


@media (min-width: 2200px){
  .asset__links__container {
    min-height: calc(100vh - 420px);
    padding: 100px 50px;
    gap: 40px !important;

  }
}