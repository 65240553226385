.searchBar {
    height: 350px;
    width: 100%;
    padding: 30px 130px 0 130px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    .searchBarContainer {
        height: 100%;
        width: 100%;
        border-radius: 30px;
        overflow: hidden;
        color: var(--text-secondary);
    }
    .optionSelector {
        display: flex;
        align-items: center;
        margin: 20px 0 0 0;
        p {
          color: var(--text-secondary);
          font-size: 1.2rem;
          font-weight: bold;
          // margin-right: 40px;
          // margin-left: 20px;
        }
        .assetSelectorBtn {
          position: relative;
          background-color: #232223;
          min-width: 180px;
          padding: 10px 70px 10px 20px;
          border: none;
          border-radius: 15px;
          font-size: 1rem;
          font-weight: bold;
          text-transform: capitalize;
          color: rgb(177, 177, 177);
          text-align: start;
          letter-spacing: 1px;
          background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAALCAYAAACd1bY6AAAAn0lEQVQokZ3RMQ4BURAG4G9f1BrRcgIVJ9i4AXdxBu7gBpqNFofQUqlcQTQKbxPZvH2saWaS+fMlkymqqlpiiymu/qsdQsADfVww6YgUOGKBQcAeq7g8dwALHFDijnmIi01HsAmN8AwfgV/BJAShEfwGtkIpLAf2clAdSNUm9nUET5h5fz0J5bAmWMa5FSJ9ZhOsT77loF+wGhxinIPgBW9HLVYNb9jfAAAAAElFTkSuQmCC");
          background-repeat: no-repeat;
          background-position: calc(100% - 15px) center;
          .assetSelectorContainer {
            position: absolute;
            top: 45px;
            left: 0;
            width: 250px;
            height: auto;
            background-color: #232223;
            border-radius: 15px;
            transition: all 0.3s ease-in-out;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            list-style: none;
            transform: scale(0);
            transform-origin: top;
            z-index: 2;
            li {
              width: 100%;
              padding: 12px 0;
              margin: 5px 0;
              color: white;
              cursor: pointer;
              &:hover {
                background-color: #3a3a3a;
              }
              &:nth-of-type(1) {
                margin-top: 0;
                border-radius: 15px 15px 0 0;
              }
              &:nth-last-of-type(1) {
                border-radius: 0 0 15px 15px;
                margin-bottom: 0;
              }
            }
            &.active {
              transform: scale(1);
            }
          }
        }
    }
}

.contentArea {
    position: absolute;
    width: 100%;
    top: 350px;
    bottom: 0;
    padding-bottom: 1.5rem;
    overflow: auto;
    z-index: 1;
    padding: 0 120px 30px 120px;
}

.noCollections {
  color: #b1b1b1;
  display: flex;
  justify-content: center;
}

@media (max-width: 1100px) {
  .searchBar,
  .contentArea {
    padding: 0 calc(5% - 10px) 30px calc(5% - 10px) !important;
  }
}