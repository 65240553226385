* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Raleway", sans-serif;
}

p {
  font-size: 14px;
}

.bold {
  font-weight: bold;
}

.left-align {
  text-align: left;
}

.center-text {
  text-align: center;
}

:root {
  --main-color: #563a4a;
  --main-color-brighter: #9d6183;
  --sidebargradient: linear-gradient(180deg, #1b1b1b 0%, #272727 50%, #1b1b1b 100%);
  --maincolorgradient: linear-gradient(90deg, #563a4a 0%, #8a5e77 50%, #412a37 100%);
  --filtercolorgradient: linear-gradient(90deg, #563a4a90 0%, #8a5e7790 50%, #412a3790 100%);
  --mainBgColor: #141414;
  --lighterbgColor: #343434;
  --mainBgColor-half-opacity: #0c0c0cf0;
  --mainBgColor-opacity-shadow: #0c0c0c3a;
  --container-blocks: #1a1a1a;
  --barsColor: #1b1b1b;
  --button-normal: #202020;
  --primary-btn-color: #272727;
  --shadow-color: #0a090a;
  --text-main: #ffffff;
  --text-secondary: #a3a3a3;
  --text-third: #626262;
  --text-inverted: #1b1b1b;
  --bg-op-inverted: #eaeaeb;
  --currentzooom: 1;

  --toastify-color-light: var(--text-main) !important;
  --toastify-color-dark: var(--barsColor) !important;
  --toastify-color-success: var(--main-color) !important;
  --toastify-color-error: #e74c3c !important;

  --toastify-icon-color-success: var(--toastify-color-success) !important;
  --toastify-icon-color-error: var(--toastify-color-error) !important;

  --toastify-font-family: sans-serif;
  --toastify-text-color-light: var(--text-secondary) !important;
  --toastify-text-color-dark: var(--text-main) !important;

}

#context-root,
#modal-root {
  z-index: 100;
}

@media (max-width: 1600px) {
  :root {
    --currentzooom: 0.8;
  }
}

@media (min-width: 2200px) {
  :root {
    --currentzooom: 1.15;
  }
}

* {
  transition: background-color 0.2s, color 0.1s;
}
