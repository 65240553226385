.container {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  
  button {
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--button-normal);
    color: var(--text-main);
    backdrop-filter: blur(50px);
    &:hover{
      background-color: var(--text-main) !important;
      color: var(--main-color) !important;
      svg * {
        fill: var(--main-color) !important;;
      }
    }
  }
}