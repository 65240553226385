.container {
  background-image: url(../../../assets/images/gears.svg),
    url(../../../assets/images/cute_thing.png);
  background-color: var(--mainBg);
  background-position: -5% 110%, 98% bottom;
  background-size: clamp(150px, 25%, 350px), clamp(150px, 25%, 350px);
  background-repeat: no-repeat;
  height: 100%;

  .mainWrapper {
    display: flex;
    align-items: center;
    color: var(--text-main);
    height: 100%;
    margin: 0 20%;
  }

  .image {
    width: 100%;
    align-self: flex-end;
  }

  .content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    .title {
      color: var(--text-secondary);
      font-size: 50px;
      font-weight: lighter;
      margin: 0;
    }

    .subtitle {
      font-size: 70px;
      font-weight: lighter;
    }

    .desc {
      font-size: 22px;
      line-height: 28px;
      font-weight: lighter;
      max-width: 300px;
    }

    button {
      font-weight: normal;
      margin-top: 15px;
      font-size: 16px;
      color: white;
      border-radius: 41px;
      background: var(
        --Linear,
        linear-gradient(90deg, #41577e 0%, #ff80fc 108.98%)
      );
      box-shadow: 0px 4px 8px 0px rgba(37, 14, 37, 0.1),
        0px 14px 14px 0px rgba(37, 14, 37, 0.09),
        0px 32px 19px 0px rgba(37, 14, 37, 0.05),
        0px 56px 23px 0px rgba(37, 14, 37, 0.01),
        0px 88px 25px 0px rgba(37, 14, 37, 0),
        0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }
  }
}

@media (max-width: 1100px) {
  .container {
    background-image: url(../../../assets/images/gears.svg);
    background-position: 95% 95%;
    background-size: clamp(300px, 40%, 450px);
    background-repeat: no-repeat;
    height: 100%;
    .mainWrapper {
        align-items: flex-start;
        margin: unset !important;
      .content {
        margin-top: 30vh;
        margin-left: 20vw;
        .subtitle {
          font-size: 25px;
        }

        .desc {
          font-size: 16px;
          line-height: 20px;
        }

        button {
          font-size: 14px;
        }
      }
    }
  }
}
