.downloadContainer,
.collectionContainer {
  width: 100%;
  height: 100%;
  position: relative;
}

.deletePopUpContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
