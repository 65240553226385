.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 20px;
    text-align: center;
    color: var(--text-main);
    .title_desc {
      font-size: 0.85rem;
      color: var(--text-secondary);
    }
  }
  .management_container {
    width: 100%;
    height: 90%;
    display: flex;
    gap: 10px;
    overflow: hidden;
    .management {
      width: 100%;
      height: 100%;
      background-color: var(--mainBgColor);
      border-radius: 10px;
      .management_header {
        height: 50px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid var(--text-main);
        .management_title {
          font-size: 1.2rem;
          font-weight: 500;
          color: var(--text-main);
        }
      }
    }
    .folder_list {
      height: 100%;
      border-radius: inherit;
      max-height: calc(100% - 50px);
      overflow-y: auto;
      width: 100%;
      padding: 20px 5px;
      display: flex;
      flex-direction: column;
      gap: 2px;
      background: inherit;
      list-style-type: none;
      .folder_item {
        font-size: 0.8rem;
        padding: 10px 5px;
        background: inherit;
        border-radius: 5px;
        transition: all 0.2s ease-in-out;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:hover {
          filter: invert(1);
        }
        .svg_icon {
          height: 70%;
          cursor: pointer;
          opacity: 0.8;
          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }
}
