.bottomAreaGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  color: var(--text-secondary);
  height: 90px;
  .bottomAreaGridChild {
   height: 100%;
   width: 100%;
   border-right: 2px solid #3e3e3e7d;
   padding-right: 10px;
   font-size: 12px;
   font-weight: bold;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
  text-align: center;
   .orangeText {
     color: var(--main-color);
     font-size: 15px;
     font-weight: bold;
   }
   p{
    margin-bottom: 10px;
    color: var(--text-secondary);
   }
   span{
    font-weight: normal;
    color: var(--text-third);
   }
 }

 & div.bottomAreaGridChild:last-child {
   border-right: none;
 }
}