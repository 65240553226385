#form-file-upload {
  height: 100%;
  width: 100%;
  max-width: 100%;
  text-align: center;
  position: relative;
  border-radius: 1rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  .auto-thumbnail-vid {
    position: relative;
    height: 75%;
    &.disabled {
      height: 25% !important;
    }
  }
  .css-doq0dk {
    overflow: hidden;
  }
  .custom-thumbnail-vid {
    position: relative;
    height: 75%;
    &.disabled {
      height: 25% !important;
    }
  }
  .thumbnail-container-radio {
    display: flex;
    gap: 10px;
  }
  .disabled {
    transition: all 0.3s ease-in-out;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      backdrop-filter: blur(3px);
      z-index: 99;
    }
  }
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  gap: 5px;
  background-color: var(--mainBgColor-opacity-shadow);
  .note {
    color: var(--text-third);
    font-size: 0.8rem;
  }
}

#label-file-upload.drag-active {
  background-color: var(--main-color);
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: 'Oswald', sans-serif;
  background-color: transparent;
  color: var(--text-secondary);
  text-decoration: underline;

}

.upload-button:hover {
  text-decoration-line: underline;
  color: var(--text-main);
}

.recommended-text {
  color: var(--text-secondary);
  font-style: italic;
  font-size: 1rem;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}