.contentPerDataTypeContainer {
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  background-color: var(--container-blocks);
  backdrop-filter: blur(20px);
  border-radius: 30px;
  padding: 20px 50px;
  display: flex;
  flex-direction: column;
  color: var(--text-main);
  .headerContainer {
    padding: 10px 20px;
    width: 100%;
    z-index: 1;
    .header {
      font-size: 20px;
      font-weight: bold;
    }
  }
  .catalogsViewContainer {
    width: 100%;
    height: 100%;
    z-index: 1;
    padding: 0 10px;
      .cardList {
      display: flex;
      overflow-x: auto;
      overflow-y: hidden;
      width: 100%;
      height: 100%;
      padding: 20px 0;
      scroll-behavior: smooth;
    }
  }
  .seeMoreContainer {
    width: 100%;
    padding: 10px 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .seeMoreLink {
      color: var(--text-main);
      text-decoration: none;
      font-weight: bold;
      font-size: 0.8rem;
      padding: 8px 25px;
      border-radius: 20px;
      background-color: var(--button-normal);
      transition: all 0.3s ease-in-out;
      &:hover {
        background-color: var(--main-color-brighter);
        transform: scale(1.03);
        color: var(--text-inverted);
      }
    }
  }
}

.navigatorLeft,
.navigatorRight {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &.navigatorRight {
    left: unset;
    right: 10px;
  }
  .carouselInteractionBtn {
    height: 100%;
    width: 100%;
    svg {
      circle {
        fill: transparent !important;
        transition: all 0.3s ease-in-out;
      }
      path {
        fill: var(--text-main);
      }
    }
    &:hover {
      svg {
        circle {
          fill: var(--text-main) !important;
        }
        path {
          fill: var(--mainBgColor);
        }
      }
    }
  }
}

@media (max-width: 700px) {
  .contentPerDataTypeContainer {
    padding: 30px 10px;
  }
}