.lightEditorContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 10px 0;
}

.sliderContainer {
    display: flex;
    align-items: center;
    gap: 15px;
    margin: 5px 0;
    .slider {
        width: 100%;
    }
    .sliderIcon {
        height: 50px;
        padding: 5px;
        svg {
            height: 80%;
        }
    }
}

.saveDLBtnContainer {
    align-self: flex-end;
    margin-top: 10px;
    .saveBtn {
        padding: 10px 5px;
        border-radius: 5px;
        color: white;
        background-color: var(--main-color);
    }
}