@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=swap');

.searchContainer {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    position: absolute;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    position: relative;

    .input-wrapper {
      border-radius: 20px;
      background: var(--button-normal);
      display: flex;
      .inputContainer {
        display: inline-block;
        position: relative;
        
        &:before {
          position: absolute;
          content: '';
          border-left: 2px solid var(--text-third);
          height: 60%;
          transform: translateY(-50%);
          top: 50%;
          left: 10px;
        }
       } 
  

      .formTextInput {
        border: none;
        padding: 12px 20px;
        font-size: 12px;
        outline: none;
        background: transparent;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        background-size: 18px;
        background-origin: content-box;
        background-repeat: no-repeat;
        background-position: center right;
        color: var(--text-main);
        font-family: 'Raleway', sans-serif;
        width: 100%;
        font-size: 0.9rem;
      }
      .searchButton {
          position: absolute;
          right: 0;
          top: 0;
          height: 100%;
          width: 70px;
          background-color: transparent;
          border-radius: 0 30px 30px 0;
          border: none;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          .searchIcon {
            width: 18px;
          }
        }
    }
}
