* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(20px);
  background-color: var(--mainBgColor-opacity-shadow);
  .popupContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .popupContent {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 10px;
      background-color: var(--mainBgColor);
      box-shadow: 2px 2px 2px 2px #74597a4d;
      &.transparent {
        // background-color: rgba(128, 128, 128, 0.539);
      }
      .sliderContainer {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}