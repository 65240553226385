.container {
  background: #ececec;
  background-size: 80%, 80%, 80%;
  background-repeat: no-repeat;
  background-position: -150% 70%, -20% 30%, 220% 85%;
}

.an__editor__container {
  width: 100%;
  padding: 40px 20px;
  min-height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.an__editor__container > div:first-child {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 50px;
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    h1 {
      color: #1b1b1b;
      font-family: Raleway, sans-serif;
      font-size: 2.5rem;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 48px */
      letter-spacing: 0.008rem;
    }

    small {
      color: #1b1b1b;
      font-family: Raleway, sans-serif;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem; /* 120% */
      letter-spacing: -0.23px;
      max-width: 392px;
    }
  }
  figure {
    width: 100%;
    max-width: 750px;
    img {
      height: 100%;
      width: 100%;
      border-radius: 20px;
    }
  }

}

.an__editor__container__button {
  display: flex;
  height: 46px;
  padding: 13px 30px;
  justify-content: center;
  align-items: center;
  max-width: 178px;
  background-color: #1b1b1b;
  margin-top: 30px;
}

@media (max-width: 1100px) {
  .an__editor__container > div:first-child {
    flex-direction: column-reverse !important;
    text-align: center !important;
    div {
      align-items: center;
    }
  }
}

@media (max-width: 500px) {
  .container {
    background-position: -25% 150%, 106% 179%, 270% -125% !important;
  }
}

