.imgArea, .fullSize {
  height: 80%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  position: relative;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  img{
    max-width: 100%;
    max-height: 600px;
    border-radius: 10px;
  }
  .bottomLeftText {
    position: absolute;
    left: 40px;
    bottom: 30px;
    width: 200px;
    color: var(--text-main);
    h3{
      font-size: 28px;
      margin-bottom: 10px;
    }
    p {
      font-weight: bold;
    }
  }
}

.fullSize {
  height: 100%;
}