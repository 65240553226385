.container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    gap: 30px;
    padding-top: 30px;
    svg{
        margin-bottom: 20px;
    }
    h3 {
        color: var(--text-secondary);
        font-size: 20px;
        font-weight: normal;
    }
    .twoButtons{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 3.4rem;
        gap: 0px;
        width: 100%;
        button{
            min-width: 50px;
            font-size: 15px;
            background-color: transparent;
            border: none;
            font-weight: bold;
            &:nth-child(2){
                color: #f11 ;
            }
            &:first-child{
                color: var(--text-secondary);
            }
        }
    }    
} 