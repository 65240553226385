.shareContainer {
  height: 100%;
  width: 100%;
  border-radius: 10px;
  background-color: var(--mainBgColor);
  display: grid;
  grid-template-columns: .5fr 1fr;

  .shareContent{
    max-height: 550px;
    padding: 30px 40px 50px 40px;
  }

  .shareUpperBackground {
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 10px 0px 0px 10px;
  }
  .shareHeader {
    margin: 10px 0;
    color: var(--text-main);
    position: absolute;
    left: 20px;
    top: 20px;
    max-width: 150px;
    font-weight:normal;
    line-height: 43px;
    font-size: 30px;
  }
  .shareButtonContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .shareButton {
      padding: 12px 40px;
      border-radius: 10px;
      border: none;
      outline: none;
      color: var(--text-main);
      background-color: var(--primary-btn-color);
      font-size: 0.8rem;
      font-weight: bold;
      cursor: pointer;
      opacity: 0.8;
      &:hover {
         opacity: 1;
         background-color: var(--primary-btn-color);
      }
      &:disabled {
        opacity: 0.5;
        cursor: default;
      }
    &.shareLinkButton {
      color: var(--text-main);
      background-color: var(--primary-btn-color);
      &:hover {
       opacity: 1;
       background-color: var(--primary-btn-color);
      }
    }
    }
  }
}

// mediaquerry less than 800px

@media (max-width: 800px) {
  .shareContainer {
    grid-template-columns: 1fr;
    .shareContent {
      padding: 30px 20px 50px 20px;
    }
    .shareHeader {
      font-size: 25px;
    }
    .shareRecipientInfo {
      .containerHeaderText {
        font-size: 1rem;
      }
      .recipientInputs {
        .upperInputs {
          .inputWrapper {
            .inputList {
              .inputItem {
                font-size: 0.7rem;
              }
            }
          }
          .selectorContainer {
            .selector {
              font-size: 0.7rem;
            }
          }
        }
      }
    }
    .shareButtonContainer {
      .shareButton {
        padding: 12px 20px;
        font-size: 0.7rem;
      }
    }
    .shareUpperBackground {
      border-radius: 16px 16px 0px 0;
    }
  }
}