@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap");
// $white: #ffffff;
// $black: #000000;

.container {
  width: 100%;
  height: 100%;
  padding: 80px 10% 20px 10%;
  color: var(--text-main);
}

