.container {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 55px;
  background-color: var(--barsColor);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 22;
  .logo {
    margin: 0 10px;
    display: flex;
    align-items: center;
    padding-left: 15px;
    .logoMobile {
      height: 0;
      width: 0;
      overflow: hidden;
    }
    .text {
      color: var(--text-secondary);
      font-weight: 500;
      letter-spacing: 1px;
      font-size: 1.1rem;
      margin-left: 10px;
    }
  }
  .user {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 0 10px;
    .profile {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }

    .menu {
      position: absolute;
      top: 60px;
      right: 15px;
      background-color: var(--container-blocks);
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
      border-radius: 15px;
      transform: scaleY(0);
      transform-origin: top;
      transition: transform 0.2s ease;
      display: flex;
      flex-direction: column;
      color: var(--text-main);
      &.active {
        transform: scaleY(1);
      }

      .info_container {
        padding: 15px 10px;
        display: flex;
        align-items: center;
        gap: 10px;
        .icon {
          height: 40px;
          width: 40px;
          border-radius: 50%;
          background-color: #ffffff7c;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .detail_container {
        display: flex;
        flex-direction: column;
        gap: 5px;
        .header {
          font-size: 1.2rem;
          font-weight: 500;
        }
        .detail {
          font-size: 0.8rem;
          font-weight: 400;
          opacity: 0.6;
          &.counter {
            margin: 5px 0;
          }
        }
      }
      .account_selection_container {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 100%;
        border-bottom: 1px solid var(--text-third);
        padding: 0 10px 20px 10px;
        .account_container {
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding: 10px 15px;
          border-radius: 10px;
          background-color: var(--button-normal);
          .comp_info {
            display: flex;
            gap: 10px;
            align-items: center;
            .ico_notfound {
              width: 30px;
              height: 30px;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 0.6rem;
              color: var(--text-secondary);
              background-color: var(--barsColor);
            }
            .company_icon {
              width: 30px;
              height: 30px;
              border-radius: 50%;
              object-fit: cover;
            }
            .company_name {
              white-space: nowrap;
              text-overflow: ellipsis;
              max-width: 150px;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
            }
          }
          .checkbox_container {
            height: 20px;
            width: 20px;
            border: 1px solid var(--main-color);
            border-radius: 50%;
            position: relative;
            .checkbox {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%) scale(0);
              width: 15px;
              height: 15px;
              border-radius: inherit;
              background-color: var(--main-color-brighter);
              transform-origin: center;
              &.active {
                transform: translate(-50%, -50%) scale(1);
              }
            }
          }
          &:hover .checkbox:not(.active) {
            transition: transform 0.2s ease;
            transform: translate(-50%, -50%) scale(1);
            opacity: 0.4;
          }
        }
      }
      .buttons_container {
        padding: 20px 10px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: space-evenly;
        align-items: center;
        .lgo_btn {
          width: 100%;
          color: var(--text-secondary);
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 10px;
          background-color: var(--button-normal);
          padding: 10px 15px;
          transition: all 0.5s ease;
          text-decoration: none;
          font-size: 0.85rem;
          &:hover {
            background-color: var(--main-color);
            color: var(--text-main);
          }
        }
      }
    }

    .circularImageContainer:hover .menu {
      display: block;
    }
  }

  img {
    max-width: 40px;
  }

  .button_up_dl_selector {
    display: flex;
    gap: 10px;
    margin: 10px 0;
    .sel_btn {
      padding: 3px 15px;
      border-radius: 15px;
      background-color: var(--button-normal);
      color: var(--text-secondary);
      font-size: 0.8rem;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      cursor: pointer;
      transition: all 0.5s ease;
      &:hover, &.active {
        background-color: var(--main-color);
        color: var(--text-main);
      }
      
    }
  }

  .progress_container {
    width: 100%;
    height: 5px;
    background-color: var(--text-third);
    border-radius: 5px;
    position: relative;
    margin-top: 1px;
    .progress {
      height: 100%;
      border-radius: 5px;
      transition: width 0.5s ease;
    }
  }

  .validation {
    margin-top: 10px;
    font-size: 0.65rem;
    font-weight: 400;
    opacity: 1;
  }
}

// // media querry less than 400px

@media (max-width: 450px) {
  .logoMobile {
    transition: all 0.5s ease-in-out;
    -moz-animation: cssAnimationOn 0s ease-in 0.5s forwards;
    -webkit-animation: cssAnimationOn 0s ease-in 0.5s forwards;
    -o-animation: cssAnimationOn 0s ease-in 0.5s forwards;
    animation: cssAnimationOn 0s ease-in 0.5s forwards;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    @keyframes cssAnimationOn {
      to {
        height: auto;
        width: auto;
        overflow: unset;
      }
    }
    svg {
      width: 25px;
      height: 25px;
    }
  }
  .logoDesktop {
    -moz-animation: cssAnimationOff 0s ease-in 0.5s forwards;
    -webkit-animation: cssAnimationOff 0s ease-in 0.5s forwards;
    -o-animation: cssAnimationOff 0s ease-in 0.5s forwards;
    animation: cssAnimationOff 0s ease-in 0.5s forwards;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;

    rotate: 360deg;
    transition: all 0.5s ease-in-out;
    @keyframes cssAnimationOff {
      to {
        height: 0;
        width: 0;
        overflow: hidden;
      }
    }
  }
}
