.wrappingElement {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.searchBarContainer {
  position: sticky;
  top: -180px;
  height: 270px;
  width: 100%;
  padding: 25px 130px;
  z-index: 3;
  background: url("../../../assets/images/blackkk2.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  .searchBar {
    position: sticky;
    top: 35px;
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00000042;
    z-index: -1;
  }

  h1 {
    color: #cbcbcb;
    text-align: center;
    margin-top: 66px;
    font-family: 'Montserrat', sans-serif;
  }
}
.contentSizingMain {
  width: 100%;
  height: 40px;
  margin-top: 25px;
  padding: 5px 140px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--text-main);
  z-index: 2;
  .tempsAmount {
    width: 200px;
    text-align: end;
    font-size: 14px;
    font-weight: lighter;
    color: var(--text-secondary);
    opacity: .7;
  }
  .linksContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    list-style: none;
    gap: 15px;
    .linkItem {
      min-width: max-content;
      .link {
        color: var(--text-secondary);
        font-weight: lighter;
        text-decoration: none;
        font-size: 0.9rem;
        &.active {
          font-weight: bold;
        }
      }
    }
  }
}

.contentSizingByFolder {
  width: 100%;
  padding: 10px 130px;
  z-index: 2;
  background-color: var(--mainBgColor);
  color: var(--text-main);
  position: sticky;
  top: 5px;

  .contentAreaHeaderMiniFilter {
    width: 100%;
    height: 40px;
    padding: 10px 0;

    .headerElement {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .headerContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        margin-top: 17px;

        h3.headerElementTitle {
          font-size: 18px;
          opacity: 1;
          font-weight: 500;
          color: var(--text-secondary);
        }

        span.headerElementTitle {
          font-size: 14px;
          opacity: .7;
          font-weight: lighter;
        }
      }
    }
  }

  .miniFiltringAndView {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    margin-top: 33px;
    .miniFilterContainer {
      display: flex;
      align-items: center;
      width: calc(100% - 100px);
      overflow: hidden;
      .l_arrowBtn,
      .r_arrowBtn {
        height: 25px;
        width: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          height: 100%;
          width: 100%;
          opacity: .4;
          &:hover {
            cursor: pointer;
            opacity: .7;
            circle {
              fill: var(--primary-btn-color) !important;
            }
            path{
              fill: var(--text-secondary);
            }
          }
          circle {
            fill: transparent !important
          }
        }
      }
      .l_arrowBtn {
        margin-right: 2px;
      }
      .r_arrowBtn {
        margin-left: 2px;
      }
      .tagsContainer {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        overflow: scroll;
        gap: 10px;
        list-style-type: none;
        scroll-behavior: smooth;
        &::-webkit-scrollbar {
          height: 0 !important;
        }
        .tagSpan {
          white-space: nowrap;
          height: 28px;
          padding: 0 8px;
          border-radius: 30px;
          display: flex;
          align-items: center;
          font-size: 12px;
          background-color: var(--main-color);
          color: white;
          opacity: 0.7;
          &:last-of-type {
            margin-right: 0;
          }
          &:hover {
            cursor: pointer;
            font-weight: bold;
            opacity: 1;
          }
        }
      }
    }

    .viewsContainer {
      display: flex;
      margin-top: 12px;
      svg {
        zoom: .8;
        margin-top: -10px;

        rect {
          opacity: .7;
        }

        &:hover {
          cursor: pointer;

          rect {
            opacity: 1;
          }
        }
      }
    }
  }

  .sizingContianer {
    height: 100%;
    display: flex;
    align-items: center;
    gap: 20px;

    .filterByRoleContainer {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 7px;

      .filterByRoleSwingContainer {
        height: 35px;
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--text-main);
        overflow: hidden;
        gap: 15px;
        margin: 10px 0;

        .roleSpan {
          padding: 15px 0;
          border-radius: 30px;
          height: 100%;
          display: flex;
          align-items: center;
          color: var(--text-secondary);
          opacity: .8;
          font-size: 15px;

          &:hover {
            cursor: pointer;
            color: var(--text-main);
            opacity: 1;
            font-weight: bold;
          }
        }
      }
    }

  }
  .sizingBtn {
    height: 90%;
    padding: 5px;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    opacity: 0.5;

    &:hover,
    &.active {
      opacity: 1;

      svg {
        filter: brightness(150%);
      }
    }
  }
}

.contentArea {
  width: 100%;
  padding-bottom: 1.5rem;
  padding: 30px 130px;
  z-index: 1;
  &.mainView {
    top: 400px !important;
  }
  .noCatalogsContainer {
    position: absolute;
    top: 150px;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .folderTemplatesContainer {
    width: 100%;
    border-radius: 30px;
    padding: 30px 50px;
    margin-bottom: 25px;
    background-color: var(--container-blocks);
    color: var(--text-main);
    position: relative;
    .folderTemplatesTitle {
      font-size: 1.2rem;
      font-weight: bold;
      margin-bottom: 15px;
      margin-left: 10px;
    }
    ::-webkit-scrollbar {
      height: 0;
    }
    *:not(.folderTemplatesTitle) {
      user-select: none;
    }
    .navigatorRight,
    .navigatorLeft {
      position: absolute;
      top: 50%;
      left: -30px;
      height: 35px;
      transform: translateY(-50%);
      width: 35px;
      z-index: 3;
      cursor: pointer;
      .carouselInteractionBtn {
        height: 100%;
        width: 100%;
        border-radius: 50%;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        cursor: pointer;
        svg {
          circle {
            fill: transparent !important;
          }
        }
        &:hover {
          background-color: var(--mainBgColor-opacity-shadow);
        }
      }
    }

    .navigatorRight {
      left: auto;
      right: -30px;
    }
    .seeMoreContainer {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      .linkMore {
        color: var(--text-main);
        text-decoration: none;
        font-size: .7rem;
        font-weight: bold;
        background-color: var(--button-normal);
        padding: 10px 20px;
        border-radius: 30px;
        &:hover {
          opacity: 0.8;
          background-color: var(--main-color-brighter);
          font-weight: 500;
          transform: scale(1.03);
        }
      }
    }
    .cardList {
      display: flex;
      overflow-x: auto;
      overflow-y: hidden;
      width: 100%;
      height: 100%;
      padding: 20px 0;
      z-index: 10;
    }
  }
}

.noCatalogs {
  position: absolute;
  top: 355px;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--text-main);
  text-align: center;
}


::-webkit-scrollbar-track {
  background: var(--mainBgColor-opacity-shadow) !important;
}

::-webkit-scrollbar-thumb {
  background: var(--mainBgColor-opacity-shadow) !important;
}

@media (max-width: 800px) {
  .searchBarContainer,
  .contentSizingMain,
  .contentSizingMain,
  .contentSizingByFolder,
  .contentArea {
    padding: 30px 5%;
    padding-top: 0 !important;
  }
  .linksContainer {
    height: unset !important;
    overflow-y: hidden;
    overflow-x: auto;
    &::-webkit-scrollbar {
      height: 0 !important;
      width: 0 !important;
    }
  }
}

@media (max-width: 500px) {
  .searchBarContainer,
  .contentSizingMain,
  .contentArea {
    padding: 30px 20px;
  }
}

@media (max-width: 600px) {
  .viewsContainer {
    display: none !important;
  }
  .miniFilterContainer {
    width: 100% !important;
  }
  .filterByRoleContainer {
    display: none !important;
  }
}

