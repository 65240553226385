/* Container holding the image and the text */
.container {
    position: relative;
    text-align: center;
    color: white;
    font-weight: bold;
}

/* Bottom left text */
.bottom-left {
    position: absolute;
    bottom: 8px;
    left: 16px;
}

/* Top left text */
.top-left {
    position: absolute;
    top: 8px;
    left: 16px;
}

/* Top right text */
.top-right {
    position: absolute;
    top: 8px;
    right: 16px;
}

/* Bottom right text */
.bottom-right {
    position: absolute;
    bottom: 8px;
    right: 16px;
}

.bottomCenter {
    position: absolute;
    bottom: 8px;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* Centered text */
.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.imageHolder {
    position: relative;
    z-index: 1;

    &::after {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(12deg, #000000f0 1%, #20202059 57%);
        z-index: -1;
        border-radius: 7px;
    }

    img {
        position: relative;
        z-index: -1
    }
}