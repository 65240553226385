.templateViewContainer {
  position: relative;
  width: 100%;
  height: 100%;
  .upperPreviewAndControlSection {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 300px;
    background-size: cover;
    background-position: center;
  }
  .templatesContainer {
    position: absolute;
    top: 300px;
    left: 0;
    width: 100%;
    height: calc(100% - 300px);
    .templatesHeader {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 50px;
      padding: 20px 70px;
      display: flex;
      justify-content: space-between;
      .headerTag {
        font-weight: 600;
        color: white;
        text-transform: capitalize;
      }
      .flipBtnContainer {
        display: flex;
        align-items: center;
        user-select: none;
        .flipBtnLabel {
          color: white;
          font-size: 0.85rem;
          margin-right: 10px;
        }
        .flipBtn {
          position: relative;
          border: none;
          background-color: transparent;
          height: 22px;
          width: 40px;
          border-radius: 30px;
          background-color: rgb(98, 98, 98);
          color: white;
          opacity: 0.9;
          cursor: pointer;
          .flipBtnInner {
            position: absolute;
            top: 2.5px;
            left: 4.5px;
            height: 17px;
            width: 17px;
            border-radius: 50%;
            background-color: rgb(160, 160, 160);
            transition: all 0.3s ease;
          }
          &:hover {
            opacity: 1;
          }
          &.active {
            background-color: var(--main-color);
            .flipBtnInner {
              background-color: white;
              left: 18.5px;
            }
          }
        }
      }
    }
    .templatesGridContainer {
      position: absolute;
      top: 50px;
      left: 0;
      width: 100%;
      bottom: 70px;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 0px 60px;
    }
    .bottomInteractionSection {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 70px;
      padding: 10px 70px;
      display: flex;
      flex-direction: row-reverse;
      align-items: flex-start;
      .downloadAllBtn {
        border: none;
        background-color: var(--main-color);
        color: white;
        padding: 10px 20px;
        border-radius: 5px;
        font-weight: bold;
        cursor: pointer;
        opacity: 0.8;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

//media querry less than  700px

@media (max-width: 700px) {
  .templatesContainer {
    .templatesHeader {
      padding: 20px !important;
      }
    }
    .templatesGridContainer {
      padding: 0px 20px !important;
    }
    .bottomInteractionSection {
      padding: 10px 20px !important;
    }
  }