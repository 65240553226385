.universal__wrapper {
  position: fixed;
  top: -45px;
  left: 0;
  bottom: 0;
  width: 250px;
  background-color: var(--barsColor);
  transition: all 0.20s ease-in-out;
  z-index: 21;
  &.closedMenu {
    left: -250px ;
    transition: all 0.25s ease-in-out;
  }
}

.defaultUniversal,
.openedMenu {
  z-index: 22 !important;
}

.uni-menu-wrapper {
  position: unset !important;
  height: 100% !important;
  width: 100% !important;
  z-index: unset !important;
  background-color: var(--barsColor) !important;
  background: var(--sidebargradient);

  .uni-menu-size {
    background-color: unset !important;
    background: inherit;
    overflow: unset !important;
  }
}

.menu_logo_btn {
  position: fixed;
  top: 70px;
  left: 25px;
  padding: 12px;
  background-color: var(--mainBgColor-opacity-shadow);
  border-radius: 10px;
  z-index: 21;
  display: flex;
  align-items: center;
  justify-content: center;
}

// media query for smaller than 1300px

// @media (max-width: 1300px) {
//   .universal__wrapper {
//     width: 200px;
//     &.closedMenu {
//       left: -200px ;
//     }
//   }
// }

// media querry smaller than 400px

@media (max-width: 450px) {
  .menu_logo_btn {
    display: none;
  }
}

.popup__container_create {
  padding: 50px;
  background-color: var(--barsColor);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .cr-text {
    color: var(--text-main);
    font-size: 1.2rem;
  }
  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    &::after {
      content: '';
      width: 50px;
      height: 50px;
      border: 5px solid var(--text-main);
      border-top: 5px solid var(--mainBgColor-opacity-shadow);
      border-radius: 50%;
      animation: spin 1s linear infinite;
    }
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}