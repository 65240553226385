$golden-rose: rgb(218, 165, 32);

.container {
  width: 100%;
  background-image: var(--banner-home);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  z-index: 10;
  padding: 3rem 0;
  height: 300px;

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 60px 0;

    h2 {
      width: 100%;
      color: white;
      text-align: center;
      font-size: 21px;
      margin-bottom: 15px;
    }

    .premBtn {
      min-width: 150px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        margin-right: 5px;

        g,
        path {
          fill: $golden-rose;
        }
      }
    }
  }

  h1 {
    font-family: Raleway, sans-serif;
    font-size: 30px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffffff;
    margin-bottom: 35px;
  }

  .premBtn {
    background: #5a5a5a32;
    color: white;
    margin: 20px auto 0 auto;
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 10px 20px;
    border: none;
    outline: none;
    border-radius: 3rem;
    cursor: pointer;
    white-space: nowrap;
    svg {
      max-width: 20px;
    }
    &:hover {
      opacity: 0.8;
    }
    &:focus {
      border: none;
      outline: none;
    }

    svg {
      width: 30px;
      margin-right: 7px;

      path {
        fill: var(--main-color);
      }
    }
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    //background-color: #00000074;
  }
}

// media queries less than 400px

@media (max-width: 400px) {
  .container {
    height: 300px;
  }
}
