body {
  /* text-align: center; */
}

.home {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.dropzone {
  /* text-align: center;
  padding: 20px;
  width: 100%;
  margin: auto; */
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: inherit;
}

.btn {
  border: none;
  text-align: center;
  background-color: var(--text-main);
  height: 50px;
  border-radius: 12px;
  color: black;
  font-weight: bold;
  transition-duration: 0.6s;
}

.btn:hover {
  background-color: blue;
  color: aliceblue;
}

.file-list {
  display: flex !important;
  flex-wrap: nowrap;
  width: auto;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 10px 20px;
  margin: 20px 30px;
  position: relative;
  object-fit: cover;
}

.file-list img {
  height: 100px;
  min-width: 200px;
  border-radius: 10px;
  background-image: url('https://img.freepik.com/premium-vector/small-size-white-gray-square-background-elements-png-background_533741-46.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.file-item {
  margin-right: 10px;
  position: relative;
}

::-webkit-scrollbar {
  width: 2px !important;
  height: 4px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--text-main);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--text-secondary);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--text-third);
}
/*
input {
  height: 100px;
  position: relative;
} */

.drop-upload-container {
  width: 100%;
  margin: auto;
  color: var(--text-main);
  line-height: 2rem;
}

.browse-btn {
  margin-top: 10px;
  font-weight: bold;
  border: none;
  background: #a58471;
  padding: 12px;;
}

.lead-txt {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
}

.center-pos {
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-title {
text-align: left;
font-weight: bold;
font-size: 19px;
}
.upload-subtitle {
display: flex;
gap: 10px;
justify-content: space-between;
flex-direction: row;
}

input[type="radio"] {
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
border-radius: 50%;
width: 16px;
height: 16px;
top: 0px;
/* background-color: transparent; */
transition: 0.2s all linear;
border: 1px solid var(--lighterbgColor);
position: relative;

}

input[type="radio"]:checked {
/* background-color: transparent; */
border: 3px solid var(--text-main);
}

.subtext {
color: var(--text-third);
text-align: left;
margin-bottom:5%
}

.drop-zone-container {
background-image: url('../../../assets/images/sharing.png');
-moz-background-size: 100% 100%;
-webkit-background-size: 100% 100%;
background-size: 100% 100%;
min-height: 320px;
border-radius: 15px;
}

.delete-image {
background: var(--mainBgColor-opacity-shadow);
position: absolute;
right: -8px;
color: var(--text-secondary);
border-radius: 50%;
font-size: 15px;
width: 23px;
height: 23px;
top: -9px;
padding-bottom: 1px;
opacity: .8;
}

.delete-image:hover {
opacity: 1;
cursor: pointer;
}

.cancel-btn {
background-color: var(--button-normal);
color: var(--text-main);
margin-right: 12px;
}

.radio-container {
gap: 7px;
display: flex;
justify-content: center;
align-items: center;
margin-right: 10px;
}

.terms-container {
display: flex;
justify-content: space-evenly;
align-items: center;
padding: 10px 0 20px 0;
}
.terms-container .dots{
display: none;
}

.required-photo-terms {
display: flex;
flex-direction: column;
justify-content: space-between;
font-size: 0.8rem;
}

.instruction-span {
line-height: 1.4rem !important;
}

.dots {
font-size: 20px;
font-weight: 700;
position: relative;
top: 3px;
}

.error-tag {
  border: 1px solid red;
}


@media screen and (max-width: 800px) {
  .terms-container {
    flex-direction: column;
    padding: 10px 20px 20px 20px;
    text-align: start !important;
    align-items: flex-start !important;
}
  .dropzone {
    min-height: unset !important;
    height: 150px !important;
  }
  .center-text {
    display: none !important;
  }
  .lead-txt {
    display: none !important;
  }
}