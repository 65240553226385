.itemsWrapper {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: max-content;
  gap: 20px;
  .counter {
    color: var(--text-secondary);
    font-weight: 500;
    font-size: 12px;
  }
  .itemFolderContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    &.addBtnType {
      height: calc(100% - 10px);
      &.disabled {
        opacity: 0.5;
        &:hover {
          &::after {
            content: "You have reached your collections count limit";
            position: absolute;
            top: -60px;
            right: 0;
            background-color: var(--button-normal);
            color: var(--text-main);
            padding: 10px;
            border-radius: 10px;
            z-index: 10;
            font-size: 0.8rem;
            width: 200px;
            text-align: center;
          }
          .addNewCollectionBtnContainer button {
            pointer-events: none;
          }
        }
      }
    }
    .imgsContainer {
      display: grid;
      grid-template-columns: 1fr 1fr;
      text-decoration: none;
      color: unset;
      height: 160px;
      width: 100%;
      background-color: var(--button-normal);
      border-radius: 10px;
      padding: 10px 20px;
      align-items: center;
      gap: 10px;
      opacity: 0.8;
      .folderImg {
        max-width: 100%;
        height: 133px;
        border-radius: 10px;
      }
      img {
        object-fit: cover;
        object-position: left bottom;
      }
      &:hover {
        opacity: 1;
      }
    }
    .interactionContainer {
      height: 60px;
      width: 100%;
      margin-top: 55px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      gap: 5px;
      z-index: 2;
      .collectionName {
        font-size: 1.2rem;
        font-weight: 600;
        color: var(--text-main);
        text-align: end;
      }
      .btn {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border: none;
        cursor: pointer;
        opacity: 1;
        transition: all 0.3s ease;
        svg {
          transform: rotate(90deg);
          margin-right: 10px;
          &.active {
            circle {
              fill: var(--text-main);
            }
          }
        }
        &:hover {
          opacity: 1;
          cursor: pointer;
          svg {
            circle {
              fill: var(--text-main);
            }
          }
        }
        &:focus {
          outline: none;
        }
        .collectionMenu {
          position: absolute;
          list-style: none;
          top: 20px;
          left: -220px;
          width: 150px;
          height: 0;
          background-color: var(--barsColor);
          border-radius: 10px;
          transform: scaleY(0);
          transform-origin: top;
          transition: all 0 ease;
          &.active {
            transition: all 0.3s ease;
            height: unset;
            transform: scaleY(1);
          }
          li {
            width: 100%;
            padding: 12px 0;
            margin: 5px 0;
            color: var(--text-main);
            cursor: pointer;
            &:not(.noCollectionShare):hover {
              background-color: var(--main-color);
              color: white !important;
            }
            &:nth-of-type(1) {
              margin-top: 0;
              border-radius: 10px 10px 0 0;
            }
            &:nth-last-of-type(1) {
              border-radius: 0 0 10px 10px;
              margin-bottom: 0;
            }
            &.noCollectionShare {
              color: var(--text-secondary);
              cursor: default;
              &:hover {
                .tooltipInfo {
                  visibility: visible;
                }
              }
              .tooltipInfo {
                position: absolute;
                top: -25px;
                right: 50%;
                visibility: hidden;
                width: max-content;
                font-size: 10px;
                background-color: var(--container-blocks);
                padding: 5px 10px;
                border-radius: 5px;
                z-index: 1;
                color: var(--text-main);
              }
            }
          }
        }
      }
    }
    .addNewCollectionBtnContainer {
      height: 100%;
      width: 100%;
      padding-bottom: 40px;
      button {
        height: 160px;
        width: 100%;
        background-color: var(--button-normal);
        cursor: pointer;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        color: var(--text-main);
        opacity: 0.7;
        flex-direction: column;
        svg {
          width: 20px;
          margin-bottom: 20px;
        }
        span {
          font-size: 3rem;
          font-weight: 600;
          margin-right: 10px;
        }
        &:hover {
          opacity: 1;
        }
      }
    }

    .noCatalogs {
      position: absolute;
      top: 10px;
      left: 15px;
      .noCatalogsText {
        font-weight: 600;
        color: var(--text-secondary);
        max-width: 150px;
        font-size: 0.9rem;
      }
    }

    .no_cats_cont {
      height: 140px;
      padding: 10px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: var(--container-blocks);
      border-radius: 10px;
      gap: 5px;
      .header_nocats {
        opacity: 0.6;
        font-size: 0.7rem;
        color: var(--text-main);
      }
      .no_cats_svg {
        height: 40%;
        opacity: 0.6;
      }
    }
  }
}

.dotLoader {
  width: 60px;
  aspect-ratio: 2;
  --_g: no-repeat radial-gradient(circle closest-side, var(--text-main) 90%, transparent);
  background: var(--_g) 0% 50%, var(--_g) 50% 50%, var(--_g) 100% 50%;
  background-size: calc(100% / 3) 50%;
  animation: l3 1s infinite linear;

  @keyframes l3 {
    20% {
      background-position: 0% 0%, 50% 50%, 100% 50%;
    }
    40% {
      background-position: 0% 100%, 50% 0%, 100% 50%;
    }
    60% {
      background-position: 0% 50%, 50% 100%, 100% 0%;
    }
    80% {
      background-position: 0% 50%, 50% 50%, 100% 100%;
    }
  }
}

@media (max-width: 1100px) {
  .itemsWrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 500px) {
  .itemsWrapper {
    grid-template-columns: 1fr;
  }
}
