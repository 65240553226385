.container {
    margin: 0 5px;
    &.absolute{
      position: absolute;
      top: 0;
      left: 0;
    }
    .profileImage {
      width: 40px;
      height: 40px;
      background-size: cover;
      background-position: top;
      border-radius: 50%;
      background-color: var(--main-color);
      color: #ffffff;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      .user_icon {
        width: 100%;
        height: 100%;
        max-width: unset;
        object-fit: cover;
      }
    }
    &.navprofile {
      .profileImage {
        width: 30px;
        height: 30px;
      }
    }
    &.newsprofile{
      .profileImage{
        position: absolute;
        left: 38%;
        top: 15px;
      }
    }
    &.profile{
      .profileImage{
        width: 130px;
        height: 130px;
      }
    }
    &.replace{
      .profileImage{
        width: 120px;
        height: 120px;
      }
    }
  }
  
  @media (min-width: 767px){
    .container{
      &.newsprofile{
        .profileImage{
        position: relative;
        top: 0%;
        left: 0;
        }
      }
    }
  }
  @media screen and (min-width: 992px) {
    .container {
      &.navprofile {
        .profileImage {
          width: 40px;
          height: 40px;
        }
      }
      &.profile{
        .profileImage{
          width: 180px;
          height: 180px;
        }
      }
      &.replace{
        .profileImage{
          width: 144px;
          height: 144px;
        }
      }
    }
  }
  
  @media screen and (min-width: 1500px) {
    .container {
      &.profile{
        .profileImage{
          width: 200px;
          height: 200px;
        }
      }
    }
  }
  
  