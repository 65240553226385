.wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: inherit;
  color: var(--text-main);
  display: flex;
  flex-direction: column;

  ::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    opacity: hidden;
  }

  ::-webkit-scrollbar-track {
    margin: 20px;
    box-shadow: inset 0 0 5px var(--mainBgColor-opacity-shadow);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(128, 128, 128, 0.623);
    border-radius: 10px;
  }

  .upperImgsSegment {
    position: absolute;
    top: 3px;
    left: 0px;
    right: 0px;
    height: 290px;

    &.inEditMode {
      height: 150px;
    }

    h4 {
      font-family: 'Raleway', sans-serif;
      line-height: 2rem;
      font-size: 1.2rem;
    }

  }

  .interactionArea {
    position: absolute;
    top: 300px;
    left: 0px;
    right: 0px;
    bottom: 65px;

    .noImgSelected {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    &.inEditMode {
      top: 150px;
    }
  }

  .uploadSection {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .right_container {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      gap: 20px;
    }
    button {
      border-radius: 15px;
      padding: 8px 20px;
      border: none;
      background-color: var(--button-normal);
      color: var(--text-main);
    }

    .submitBtn {
      background-color: var(--main-color);
      margin-left: 10px;
      cursor: pointer;

      &.inactive {
        background-color: var(--button-normal);
      }
    }
  }

  .modalWrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    backdrop-filter: blur(3px);
    z-index: 3;

    .tagPopupContainer {
      position: absolute;
      top: calc(50%);
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 10px;
      padding: 5px 50px;
      height: 200px;
      width: 350px;
      background-color: var(--container-blocks);
      backdrop-filter: var(--text-third) solid;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;

      input {
        padding: 10px 0;
        outline: none;
        border: none;
        width: 100%;
        border-bottom: 1px solid var(--text-third);
        background-color: transparent;
        color: white;
        font-size: 1.1rem;
      }

      .btnContainer {
        align-self: flex-end;

        .btn {
          border: none;
          border-radius: 10px;
          background-color: var(--container-blocks);
          color: white;
          font-size: 1rem;
          letter-spacing: .5px;
          padding: 7px 12px;
          margin-left: 10px;

          &.addBtn {
            background-color: var(--main-color);
          }
        }
      }
    }
  }

}

.cancel {
  cursor: pointer;
}