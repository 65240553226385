.container {
  display: flex;
  justify-content: center;
  padding: 80px 0;
}

.reports__container {
  width: 100%;
  max-width: 1156px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 16px;
  overflow: hidden;
}

.reports__listing {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;
}

.reports__container > h1 {
  align-self: flex-start;
  font-family: Raleway, sans-serif;
  font-size: 30px;
  font-weight: 600;
  line-height: 35px;
  letter-spacing: 0;
  color: var(--text-main);
}


.reports__container > small {
  font-family: Raleway, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.23000000417232513px;
  color: var(--text-secondary);
  align-self: flex-start;
  margin-bottom: 40px;
}

@media (max-width: 700px) {
  .reports__listing {
    flex-wrap: wrap !important;
  }
}