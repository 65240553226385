@import "../../../../assets/scss/mixins";

.container * {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.container {
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  backdrop-filter: blur(3px);
}

.add__files__cancel__btn {
  background-color: var(--button-normal);
  color: var(--text-secondary);
  margin-right: 12px;
  justify-content: center;
  align-items: center;
  padding: 10px 25px;
}

.add__files__button {
  background-color: var(--button-normal);
  margin-right: 1rem ;
  padding: 10px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add__files__footer__section {
  bottom: 15px;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 1.5rem;
  padding-top: 1rem;
  margin-left: 17px;
}

