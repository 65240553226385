.wraper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  .nxt_button {
    padding: 15px 35px;
    border-radius: 30px;
    background-color: var(--primary-btn-color);
    color: var(--text-main);
    font-size: 1.2rem;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    &:hover {
      border: 1px solid transparent;
      background-color: var(--text-main);
      color: var(--mainBgColor);
    }
  }
  .interactions {
    display: flex;
    align-items: center;
    height: 100%;
    height: 100%;
    .pageinput {
      height: 40px;
      min-width: 40px;
      max-width: 60px;
      padding: 5px 2px;
      border-radius: 7px;
      font-size: 1.2rem;
      background-color: var(--primary-btn-color);
      color: var(--text-main);
      border: none;
      text-align: center;
      margin: 0 10px;
      box-shadow: var(--shadow-color) 0px 0px 5px 0px;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      &:focus {
        outline: none;
      }
    }
    .arrows {
      height: 40px;
      cursor: default;
      width: 40px;
      border-radius: 7px;
      background-color: var(--primary-btn-color);
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s ease-in-out;
      box-shadow: var(--shadow-color) 0px 0px 5px 0px;
      svg {
        height: 25px !important;
        width: 25px !important;
        circle {
          fill: transparent !important;
        }
        path {
          fill: var(--text-main) !important;
        }
      }

      &.disabled {
        cursor: default !important;
        opacity: 0.7 !important;
        background-color: transparent !important;
      }
      
      &:hover:not(.disabled) {
        border: 1px solid transparent;
        background-color: var(--text-main);
        color: var(--mainBgColor);
        path {
          fill: var(--mainBgColor) !important;
        }
      }
    }
    .pageTotal {
      color: var(--text-main);
      margin-left: 10px;
    } 
  }
}
