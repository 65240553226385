.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;
  background-image: url(../../../assets/images/gears.svg);
  background-position: -5% 110%;
  background-size: clamp(150px, 25%, 350px);
  background-repeat: no-repeat;

  .paragraph {
    font: normal normal normal 25px/30px Raleway;
    margin-top: 20px;
    color: var(--text-secondary);
  }
}

@media (max-width: 1100px) {
  .container {
    background-image: url(../../../assets/images/gears.svg);
    background-position: 95% 95%;
    background-size: clamp(300px, 40%, 450px);
    background-repeat: no-repeat;
    height: 100%;
  }
}