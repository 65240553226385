.container * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.easy__upload__container {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: #f5f5f5;
  background-color: #141414;
  padding: 40px 20px;
  min-height: 700px;
}

.easy__upload__container > div:first-child {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 100px;
  figure {
    width: 100%;
    max-width: 750px;
    img {
      width: 100%;
      object-fit: contain;
      border-radius: 20px;
      box-shadow: 0px 0px 150px 0px var(--main-color);
    }
  }

  .text_container {
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-self: center;
    h1 {
      color: #fff;
      font-family: Raleway, sans-serif;
      font-size: 2.5rem;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      letter-spacing: 0.008rem;
      margin-bottom: 30px;
    }

    small {
      color: #d8d8d8;
      font-family: Raleway, sans-serif;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem; /* 120% */
      letter-spacing: -0.014rem;
      margin-bottom: 30px;
    }
  }
}

.easy__upload__container__button {
  display: flex;
  height: 46px;
  padding: 13px 30px;
  justify-content: center;
  align-items: center;
  max-width: 178px;
  background-color: #2f2f2f3d;
  backdrop-filter: blur(15px);
}

@media (max-width: 1100px) {
  .easy__upload__container > div:first-child {
    text-align: center !important;
    flex-direction: column !important;
    div {
      align-items: center;
    }
  }
  .imgFigure img {
    box-shadow: 0px 20px 150px 0px var(--main-color);
  }
}
