.shareContainer {
  height: 100%;
  width: 100%;
  border-radius: 10px;
  background-color: var(--mainBgColor);
  min-height: 450px;
  display: grid;
  grid-template-columns: .5fr 1fr;

  .shareContent{
    max-height: 550px;
    padding: 30px 40px 50px 40px;
  }

  .shareUpperBackground {
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 10px 0px 0px 10px;
  }
  .shareHeader {
    margin: 10px 0;
    color: var(--text-main);
    position: absolute;
    left: 20px;
    top: 20px;
    max-width: 150px;
    font-weight:normal;
    line-height: 43px;
    font-size: 30px;
  }
  .shareRecipientInfo {
    margin: 10px 0;
    padding: 5px 0;
    width: 100%;
    color: var(--text-main);
    .containerHeaderText {
      margin: 10px 0;
      color: var(--text-secondary);
      font-weight: normal;
      font-size: 1.1rem;
    }
    .recipientInputs {
      width: 100%;
      display: flex;
      align-items: center;
      &:hover {
        .autoCompleteList {
          display: flex !important;
        }
      }
      .upperInputs {
        width: 100%;
        display: flex;
        padding: 5px 0;
        margin-bottom: 10px;
        .inputWrapper {
          width: 90%;
          border-radius: 10px;
          background-color: var(--barsColor);
          color: var(--text-main);
          max-width: 600px;
          margin-right: 20px;
          padding: 10px 20px;
          display: flex;
          flex-direction: column;
          .inputList {
            list-style-type: none;
            margin-bottom: 10px;
            display: flex;
            gap: 10px;
            flex-wrap: wrap;
            .inputItem {
              font-size: 0.85rem;
              font-weight: bold;
              background-color: var(--primary-btn-color);
              padding: 7px 12px;
              border-radius: 15px;
            }
          }
          .inputContainer {
            height: 100%;
            width: 100%;
            position: relative;
            .input {
              height: 100%;
              width: 100%;
              z-index: 2;
              height: 30px;
              border: none;
              background-color: transparent;
              outline: none;
              color: var(--text-secondary);
              &::placeholder {
                color: var(--text-secondary);
                font-size: 0.7rem;
              }
            }
            .autoCompleteList {
              display: none;
              z-index: 1;
              position: absolute;
              top: 35px;
              left: 0;
              width: 60%;
              background-color: var(--button-normal);
              border-radius: 10px;
              flex-direction: column;
              overflow-y: auto;
              overflow-x: none;
              max-height: 280px;
              transition: all 0.3s ease-in-out;
              li {
                &:first-child {
                  border-radius: 10px 10px 0 0;
                }
                &:last-child {
                  border-radius: 0 0 10px 10px;
                }
                font-size: 0.7rem;
                padding: 10px 20px;
                cursor: pointer;
                &:hover {
                  background-color: var(--main-color);
                }
              }
            }
          }
        }
        .selectorContainer {
          width: 40%;
          max-width: 600px;
          height: 50px;
          display: flex;
          flex-direction: column;
          position: relative;
          .selector {
            position: relative;
            width: 100%;
            height: 100%;
            border-radius: 10px;
            border: none;
            outline: none;
            padding: 0 20px;
            max-width: 600px;
            background-color: var(--barsColor);
            color: var(--text-main);
            font-size: 0.7rem;
            &::after{
              position: absolute;
              content: "";
              top: 23px;
              right: 10px;
              width: 10px;
              height: 10px;
              background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAALCAYAAACd1bY6AAAAn0lEQVQokZ3RMQ4BURAG4G9f1BrRcgIVJ9i4AXdxBu7gBpqNFofQUqlcQTQKbxPZvH2saWaS+fMlkymqqlpiiymu/qsdQsADfVww6YgUOGKBQcAeq7g8dwALHFDijnmIi01HsAmN8AwfgV/BJAShEfwGtkIpLAf2clAdSNUm9nUET5h5fz0J5bAmWMa5FSJ9ZhOsT77loF+wGhxinIPgBW9HLVYNb9jfAAAAAElFTkSuQmCC");
              background-repeat: no-repeat;
              background-size: contain;
            }
            span {
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;
              height: 100%;
            }
            .label {
              color: var(--text-secondary);
            }
            .dropDownMenu {
              position: absolute;
              top: 32px;
              left: 0;
              width: 100%;
              background-color: inherit;
              border-radius: 0 0 10px 10px;
              display: flex;
              flex-direction: column;
              overflow-y: auto;
              overflow-x: none;
              height: 0;
              &.active {
                height: auto;
                padding: 10px 0;
                transition: all 0.3s ease-in-out;
              }
              li {
                font-size: 0.7rem;
                padding: 10px 20px;
                cursor: pointer;
                &:hover {
                  background-color: var(--main-color);
                }
              }
            }
          }
        }
      }
    }
  }
  .shareButtonContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .shareButton {
      padding: 12px 40px;
      border-radius: 10px;
      border: none;
      outline: none;
      color: var(--text-main);
      background-color: var(--primary-btn-color);
      font-size: 0.8rem;
      font-weight: bold;
      cursor: pointer;
      opacity: 0.8;
      &:hover {
         opacity: 1;
         background-color: var(--primary-btn-color);
      }
      &:disabled {
        opacity: 0.5;
        cursor: default;
      }
    &.shareLinkButton {
      color: var(--text-main);
      background-color: var(--primary-btn-color);
      &:hover {
       opacity: 1;
       background-color: var(--primary-btn-color);
      }
    }
    }
  }
}

// mediaquerry less than 800px

@media (max-width: 800px) {
  .shareContainer {
    grid-template-columns: 1fr;
    .shareContent {
      padding: 30px 20px 50px 20px;
    }
    .shareHeader {
      font-size: 25px;
    }
    .shareRecipientInfo {
      .containerHeaderText {
        font-size: 1rem;
      }
      .recipientInputs {
        .upperInputs {
          .inputWrapper {
            .inputList {
              .inputItem {
                font-size: 0.7rem;
              }
            }
          }
          .selectorContainer {
            .selector {
              font-size: 0.7rem;
            }
          }
        }
      }
    }
    .shareButtonContainer {
      .shareButton {
        padding: 12px 20px;
        font-size: 0.7rem;
      }
    }
    .shareUpperBackground {
      border-radius: 16px 16px 0px 0;
    }
  }
}