.button {
  display: inline-block;
  padding: 10px 20px;
  border: none;
  outline: none;
  border-radius: 3rem;
  margin: 2px 0;
  cursor: pointer;
  white-space: nowrap;
  svg{
    max-width: 20px;
  }
  &:hover {
    opacity: 0.8;
  }
  &:focus {
    border: none;
    outline: none;
  }
}
