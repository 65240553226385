.container {
  display: flex;
  justify-content: center;
}

.container * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.templates__container {
  width: 100%;
  max-width: 1156px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 128px;
  overflow: hidden;
}

.templates__container h1:first-child {
  color: var(--text-main);
  font-family: Raleway, sans-serif;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 48px */
  letter-spacing: 0.008rem;
  margin-bottom: 24px;
}

.templates__container small:nth-child(2) {
  color: var(--text-secondary);
  font-family: Raleway, sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem; /* 142.857% */
  letter-spacing: -0.014rem;
  margin-bottom: 40px;
}

.templates__wrapper {
  display: flex;
}

.templates__wrapper_row {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  height: 160px;
  gap: 20px;
  padding-right: 25px;
  img {
    height: 160px;
    width: 100%;
  }
}

.templates__wrapper_row_unshowcased {
  display: flex;
  flex-flow: row wrap !important;
  width: 100%;
}

// media querry less than 1100px
@media (max-width: 1100px) {
  .container {
    text-align: center !important;
  }
}

@media (max-width: 700px) {
  .templates__wrapper_row_unshowcased  {
    max-height: 800px;
    overflow-x: hidden;
    overflow-y: scroll;
  }
}