.sharePageWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 20px 8%;
  overflow-y: auto;
  overflow-x: hidden;
  background: url("../../../assets/images/open_frame.jpg");
  // background-size: 100%;
  background-size: cover;
  background-position: center;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
    z-index: 1;
  }
}

.main__frame {
  position: absolute;
  top: 2%;
  bottom: 2%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 1100px;
  background-color: hsla(0, 0%, 10%, 0.6);
  z-index: 2;
  padding: 20px 40px;
  border-radius: 10px;
  backdrop-filter: blur(20px);
  .header__container {
    position: absolute;
    top: 20px;
    left: 40px;
    right: 40px;
    height: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 10px;
    color: #dadada;
    svg {
      width: 100px;
      height: 100px;
    }
    .title {
      font-size: 1.7rem;
      font-weight: 700;
    }
    .sub_title {
      font-size: 1.1rem;
      font-weight: 500;
      color: #aeaeae;
    }
  }
  .main__container {
    position: absolute;
    top: 20%;
    left: 40px;
    right: 40px;
    bottom: 80px;
    padding-top: 20px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .collectionInfoContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: #e6e6e6;
      font-size: 1.5rem;
      font-weight: 700;
      margin-bottom: 5px;
      .gallery__count {
        font-size: 1rem;
        color: #8e8e8e;
        font-weight: 500;
        font-size: 0.9rem;
      }
    }
    .contentData {
      height: 100%;
      width: 100%;
      margin-left: -10px;
      margin-right: -10px;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
  .footer {
    position: absolute;
    z-index: 3;
    top: calc(100% - 80px);
    bottom: 20px;
    left: 40px;
    right: 40px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    color: #8e8e8e;
    .pwrd {
      font-size: 0.6rem;
      font-weight: 500;
      a {
        color: #8e8e8e;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .sign_int_container {
      display: flex;
      flex-direction: column;
      gap: 5px;
      align-items: flex-end;
      ._q {
        font-size: 0.6rem;
        font-weight: 500;
      }
      .btn_container {
        display: flex;
        gap: 10px;
        .sign_up_btn,
        .sign_in_btn {
          cursor: pointer;
          text-decoration: none;
          font-size: 0.7rem;
          font-weight: bold;
          color: #d2d2d2;
          background-color: #3a2343;
          padding: 5px 8px;
          border-radius: 25px;
          opacity: 0.8;
          transition: all 0.2s ease-in-out;
          &:hover {
            opacity: 1;
          }
        }
        .sign_in_btn {
          background-color: #130b17;
        }
      }
    }
  }
}

// media querry less than 500px
@media (max-width: 500px) {
  .main__frame {
    padding: 20px 20px;
    top: 0 !important;
    bottom: 0 !important;
    border-radius: 0 !important;
    .header__container {
      flex-direction: row !important;
      svg {
        width: 80px;
        height: 80px;
      }
      .title {
        font-size: 1rem !important;
      }
      .sub_title {
        font-size: 0.8rem !important;
      }
    }
    .collectionInfoContainer {
      font-size: 0.9rem !important;
      .gallery__count {
        font-size: 0.7rem !important;
      }
    }
  }
}