.shareContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  background-color: var(--mainBgColor);
  position: relative;
  padding: 30px 40px;
   .shareHeader {
    position: absolute;
    color: var(--text-main);
    font-weight: normal;
    font-size: 1.8rem;
    top: 0;
    left: 0;
    right: 0;
    height: 80px;
    display: flex;
    align-items: center;
    padding-left: 40px;
    background-image: url(https://publisher-frontend-prod-data.s3.us-west-1.amazonaws.com/mpublisher/Group_40538_hogamw.png);
    background-position: center;
    background-size: cover;
    border-radius: 10px 10px 0 0;
  }
  .manageUlContainer {
    height: 100%;
    width: 100%;
    overflow: hidden;
    padding-top: 80px;
    .manageListContainer {
      margin-top: 10px;
      height: calc(100% - 55px);
      width: 100%;
      max-height: 400px;
      border-radius: 0 0 30px 30px;
      padding: 0 10px;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      overflow-x: hidden;
      &::-webkit-scrollbar-track {
        margin-bottom: 30px;
      }
      .headingListItems {
        padding: 5px 0 20px 0;
        margin: 1px 0;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .collection_dataInfo {
          display: flex;
          align-items: center;
          color: var(--text-main);
          width: 100%;
          font-weight: bold;
          &:last-child {
            width: 50px !important;
          }
        }
      }
      .manageListItemsContainer {
        padding: 10px 0;
        margin: 1px 0;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: var(--text-main);
        text-decoration: none;
        transition: all 0.2s ease-in-out;
        .collection_dataInfo {
          display: flex;
          align-items: center;
          width: 100%;
        }
        .btnInteractionContainer {
          display: flex;
          align-items: center;
          height: 100%;
          .removeBtn {
            height: 22px;
            width: 22px;
            border-radius: 50%;
            border: none;
            background-color: var(--button-normal);
            color: var(--text-main);
            font-size: 0.7rem;
            cursor: pointer;
            &:hover {
              background-color: var(--main-color);
            }
          }
        }
        &:hover {
          background-color: var(--barsColor);
        }
      }
    }
  }

  .shareContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: 550px;
    padding: 10px 0 0 0;
  }
 
  .closeButtonContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 25px;
    .closeButton {
      padding: 12px 40px;
      border-radius: 10px;
      border: none;
      outline: none;
      background-color: var(--primary-btn-color);
      color: var(--text-secondary);
      font-size: 0.8rem;
      font-weight: bold;
      cursor: pointer;
      opacity: 0.8;
      &:hover {
        opacity: 1;
        background-color: var(--main-color-brighter)
      }
      &:disabled {
        opacity: 0.5;
        cursor: default;
      }
    &.shareLinkButton {
      background-color: var(--main-color);
      color: var(--text-main);
      &:hover {
        background-color: var(--text-main);
        color: var(--main-color);
      }
    }
    }
  }
}
