.popUpContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 40px 50px;
  // border: 1px solid pink;

  // .topArea {
  //   position: absolute;
  //   height: 50px;
  //   width: 100%;
  //   display: flex;
  //   justify-content: space-between;
  //   align-items: center;
  //   padding: 0 20px;  }

  //   .middleArea {
  //     position: absolute;
  //     height: 100%;
  //     width: 100%;
  //     display: flex;
  //     flex-direction: column;
  //     justify-content: center;
  //     align-items: center;
  //   }

  //   .bottomArea {
  //     height: 50px;
  //     width: 100%;
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     padding: 0 20px;
  //   }
}

// media querry less than 800px

@media (max-width: 800px) {
  .popUpContainer {
    padding: 40px 20px;
  }
}