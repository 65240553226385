.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  // padding: 12px 16px;
  position: relative;
  background: transparent;
  border: none;
  color: var(--text-main);
  cursor: pointer;
  border-radius: 20px;
  background-color: var(--button-normal);
  height: 30px;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  box-sizing: border-box;
  // margin: 0px 2px;
  margin-right: 2px;
  cursor: pointer;
  &:hover {
    background-color: var(--text-main);
    color: var(--main-color);
  }
  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  i {
    left: 10px;
    padding-right: 4px;
    margin-right: 10px;
    svg {
      height: 11px;
    }
  }
}
