.itemsWrapper {
  width: 100%;
  height: 100%;
}

// @media (max-width: 1930px) {
//   .itemsWrapper {
//     grid-template-columns: repeat(auto-fill, 32%);
//     gap: 20px;
//   }
// }

// @media (max-width: 1400px) {
//   .itemsWrapper {
//     grid-template-columns: repeat(auto-fill, 30%);
//   }
// }
