.container * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.horizontal__menu__container {
  width: 100%;
  height: 70px;
  background: var(--maincolorgradient);
  justify-content: center;
  align-items: center;
  display: flex;
  padding-top: 10px;
}

.horizontal__menu__container div {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  color: #FFFFFF;
  margin-top: auto;
  margin-bottom: auto;
  height: 100%;
  width: 428px;
  flex-wrap: inherit;
}

.horizontal__menu__container div div {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  justify-content: center;
  align-items: center;
  width:100%;
}

.horizontal__menu__container div:nth-child(2) hr {
  width: 100px;
}

.horizontal__menu__container div:nth-child(3) hr {
  width: 60px;
}


.horizontal__menu__container div p {
  cursor: pointer;
  font-size: 18px;
}

.horizontal__menu__container hr {
  width: 100px;
}

.horizontal__menu__container__hide {
  opacity: 0;
}

.horizontal__menu__container__display {
  opacity: 100%;
}

.horizontal__menu__container__deactivate {
  color: #CED1D2;
}

hr::after{
  background-color: rgb(255, 255, 255) !important;
  height: 1px !important;
}